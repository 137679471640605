// TODO: Replace missing Analytics to valid IDs

export const ADD_ADDRESS_PICKUP_POINT = {
  INTERFACE_ID: 'XXXXXXXXXX',
  LOAD: 'XXXXXXXXXX',
  ON_BACK: 'XXXXXXXXXX',
  ON_NEXT: 'XXXXXXXXXX',
  ON_SETUP_LATER: 'XXXXXXXXXX',
  ON_CLICK_DETAILS: 'XXXXXXXXXX',
  ON_CLICK_DIRECTIONS: '.XXXXXXXXXX',
  ON_CLICK_LOCKERS: 'XXXXXXXXXX',
  ON_MAP_PIN_CLICK: 'XXXXXXXXXX',
  ON_SELECT: 'XXXXXXXXXX',
  ON_CLICK_SHOPS: 'XXXXXXXXXX',
};

export const MAIN_MENU = {
  CLICK_ACCOUNT: 'vK4bRvGGAqACjbwM',
  CLICK_BASKET: 'bKIbRvGGAqACjbvx',
  CLICK_ON_DPD_LOGO: '5xAbRvGGAqACjbvQ',
  CLICK_PICKUPFINDER: '0bvrRvGGAqACjbu3',
  CLICK_RETURN: 's8brRvGGAqACjbt_',
  CLICK_SEND: 'hZUUXfGGAqACjl2l',
  CLICK_SIGN_IN: 'alkbRvGGAqACjbwl',
  CLICK_SIGN_OUT: 'Click Sign Out Action ID',
  CLICK_TRACK: 'VuzrRvGGAqACjbtm',
  CLICK_HELP: '',
  LOAD: 'Pi7xRvGGAqACjaWh',
};

export const ENTER_EMAIL = {
  INTERFACE_ID: 'kqTqqfGGAqACjgpA',
  LOAD: 'UPLzqfGGAqACjh7K',
  ON_EMAIL_INPUT: '2_3zqfGGAqACjh7h',
  ON_SEND: 'gs4LqfGGAqACjh8P',
  ON_BACK: '38gLqfGGAqACjh74', // NOTE: No back button in designs, clarify
};

export const EMAIL_SENT = {
  INTERFACE_ID: '.NHqqfGGAqACjgpW',
  LOAD: '9wQ4afGGAqACjkDA',
  ON_RESEND: 'Vus4afGGAqACjkDs',
  ON_BACK: 'Kco4afGGAqACjkDV',
};

export const VERIFICATION = {
  INTERFACE_ID: 'Fs_qqfGGAqACjgps',
  LOAD: 'eg6EafGGAqACjkXA',
  ON_ERROR: 'qXzjE_GGAqACjjiF', // NOTE: Verify if we need these action IDs
  ON_SUCCESS: 'OHjjE_GGAqACjjh1', // NOTE: Verify if we need these action IDs
};

export const PICKUPFINDER = {
  INTERFACE_ID: 'LNJ42fGGAqACjqVr',
  LOAD: '1brk2fGGAqACjqnb',
  ONCLICK_DROPDOWN: 'KgyU2fGGAqACjqn.',
  ONCLICK_SEARCH: '4eJU2fGGAqACjqpI',
  ONENTER_TOWN_OR_POSTCODE: 'bC2U2fGGAqACjqoT',
};

export const HOME = {
  INTERFACE_ID: 'SvrBRvGGAqACjaNr',
  "CLICK_ON_'DESTINATION_COUNTRY'_DROPDOWN": 'dny9lfGGAqACjmRL',
  CLICK_GET_A_QUOTE: 'tIdMR_GGAqACji3w',
  ENTER_POSTCODE: '2pbMR_GGAqACji4Z',
  LOAD: 'Q.RxRvGGAqACjaVP',
};

export const QUICK_QUOTE = {
  INTERFACE_ID: 'zTY.u_GGAqACjtQ.',
  CLICK_GET_QUOTE: 'Rxa3u_GGAqACjthP',
  ENTER_POSTCODE_OR_CITY: 'yD93u_GGAqACjtiU',
  LOAD: 'dMM3u_GGAqACjtgr',
  SELECT_DESTIONATION_COUNTRY: 'RZ73u_GGAqACjtip',
};

export const PARCEL_DETAILS = {
  INTERFACE_ID: 'Parcel details interface ID',
  LOAD: 'Parcel details load ID',
};

// TODO: Replace Analytics to valid IDs
export const BASKET = {
  INTERFACE_ID: 'Basket interface ID',
};

export const RETURN = {
  INTERFACE_ID: 'RETURN interface ID',
};

export const PRODUCT_AND_SERVICES = {
  INTERFACE_ID: 'Product and Services interface ID',
};

export const TRACK = {
  INTERFACE_ID: 'Track interface ID',
};

export const PAYMENT = {
  INTERFACE_ID: 'Payment interface ID',
  CLICK_CHECKOUT: 'Click Checkout Action ID',
  CLICK_SUBMIT: 'Click Submit Action ID',
  CLICK_VIEW_CART: 'Click View Cart Action ID',
  CLICK_BACK: 'Click Back Action ID',
};

export const PAYMENT_SUCCESS = {
  INTERFACE_ID: 'Payment success interface ID',
  LOAD: 'LOAD Payment success event XXXXXXXXXX',
};

export const PAYMENT_MODAL = {
  INTERFACE_ID: 'Payment Modal interface ID',
};

export const ORDER_ADDRESS_DETAILS = {
  INTERFACE_ID: 'ORDER ADDRESS DETAILS interface ID',
  LOAD: 'LOAD event XXXXXXXXXX',
};

export const USER_PREFERENCES = {
  INTERFACE_ID: 'lfyWlwmGAqACgK2K',
  LOAD: 'D.aURImGAqACoQFW',
};
