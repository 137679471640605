import { useCallback } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Loader from '../../features/Loader/Loader.jsx';
import { ORDER } from '../../constants/routes.js';
import { FIELDS } from '../../constants/forms.js';
import Error from '../Auth/SignIn/Error.jsx';
import * as QuotesActions from './actions';

const Quotas = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const load = useCallback(
    async params => dispatch(QuotesActions.load(params)).unwrap(),
    [dispatch]
  );

  return (
    <Loader
      promiseFn={load}
      deliveryCountryCode={searchParams.get(FIELDS.DESTINATION_COUNTRY.KEY)}
      deliveryAddressKey={searchParams.get(FIELDS.ADDRESS_KEY.KEY)}
      errorComponent={error => <Error values={{ error }} />}
    >
      {shipmentDto => (
        <Navigate to={ORDER} state={{ shipment: shipmentDto }} replace />
      )}
    </Loader>
  );
};

export default Quotas;
