import { useState } from 'react';
import { Box, Typography } from '@mui/material';

import GalleryWidget from '../../GalleryWidget';
import { ORDER_DETAILS } from '../../../constants/strings';
import { getImageUrl } from '../../../utils/images';
import GalleryIconButton from '../../GalleryIconButton';
import notFoundImg from '../../../assets/images/image-not-found.png';
import mapNotAvailable from '../../../assets/images/mapNotAvailable.jpeg';
import { STRINGS } from '../../../constants';

const TrackingSection = ({ mapSrc, images }) => {
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleMapError = () => setImageError(true);
  const openGallery = () => setIsGalleryOpen(true);

  const isEmpty = images.length > 0;
  const showBluredMap = imageError || !mapSrc;

  return (
    <>
      <Typography
        variant='body2'
        color='textSecondary'
        sx={{
          fontFamily: 'Roboto',
          my: 1,
        }}
      >
        {STRINGS.TRACKING}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Box
          sx={{
            width: {
              xs: '100%',
              md: isEmpty ? '50%' : '100%',
            },
          }}
        >
          <Box
            component='img'
            src={showBluredMap ? mapNotAvailable : mapSrc}
            alt='Map Preview'
            width='100%'
            sx={{
              borderRadius: 1,
              filter: showBluredMap ? 'blur(5px)' : 'none',
            }}
            onError={handleMapError}
          />
        </Box>

        {isEmpty && (
          <>
            <GalleryWidget
              title={ORDER_DETAILS.GALLERY_TITLE}
              images={images}
              isOpen={isGalleryOpen}
              onClose={() => setIsGalleryOpen(false)}
            />
            <Box
              sx={{
                display: 'flex',
                width: { xs: '100%', md: '50%' },
                position: 'relative',
              }}
            >
              <Box
                component='img'
                src={getImageUrl(images[0])}
                alt='Gallery Thumbnail'
                width='100%'
                sx={{ borderRadius: 1, cursor: 'pointer' }}
                onError={e => (e.target.src = notFoundImg)}
                onClick={openGallery}
              />
              <GalleryIconButton
                imagesNumber={images.length}
                onClick={openGallery}
              />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default TrackingSection;
