import { get } from 'lodash';
import { SHIPMENT_FIELDS } from '../../../../constants/forms';
import { FREE_PROTECTION_DEDUCTION } from '../../constants';

export const getFieldsToUpdate = ({ network, values }) => {
  const isLiabilityValueAvailable =
    network.insurance && !get(values, SHIPMENT_FIELDS.LIABILITY_VALUE.KEY);

  return {
    [SHIPMENT_FIELDS.NETWORK_DESC.KEY]: network.networkDesc,
    [SHIPMENT_FIELDS.NETWORK_BUS_ZONE.KEY]: network.busZone,
    [SHIPMENT_FIELDS.LIABILITY.KEY]: network.insurance,
    [SHIPMENT_FIELDS.NETWORK_EXPORT_IMPORT_REQUIRED.KEY]:
      network.exportImportRequired,
    [SHIPMENT_FIELDS.NETWORK_EORI_REQUIRED.KEY]: network.eoriRequired,
    [SHIPMENT_FIELDS.NETWORK_VAT_NUMBER.KEY]: network.vatRequired,
    [SHIPMENT_FIELDS.NETWORK_PID_NUMBER.KEY]: network.pidRequired,
    [SHIPMENT_FIELDS.NETWORK_UKIMS_NUMBER.KEY]: network.ukimsRequired,
    [SHIPMENT_FIELDS.NETWORK_EXPORT_REASON_REQUIRED.KEY]:
      network.exportReasonRequired,
    [SHIPMENT_FIELDS.NETWORK_DT1_AVAILABLE.KEY]: network.dt1Available,
    [SHIPMENT_FIELDS.NETWORK_DDP_AVAILABLE.KEY]: network.ddpAvailable,
    [SHIPMENT_FIELDS.NETWORK_FDA_REQUIRED.KEY]: network.fdaRequired,
    [SHIPMENT_FIELDS.NETWORK_COST_REQUIRED.KEY]: network.costsRequired,
    [SHIPMENT_FIELDS.NETWORK_PROFORMA.KEY]: network.proforma,
    ...(isLiabilityValueAvailable && {
      [SHIPMENT_FIELDS.LIABILITY_VALUE.KEY]: FREE_PROTECTION_DEDUCTION,
    }),
  };
};
