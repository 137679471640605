import { useCallback, useMemo } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { debounce } from 'lodash';
import { useWizard } from './Context';

const WizardForm = ({ children, subscribeOnChange = true, ...props }) => {
  const { setValues, values } = useWizard();

  // NOTE: Initial values can be set after asynchrounous operation, please be aware how often you change them (better not more than once)
  const stepInitialValues = useMemo(
    () => props.initialValues || values,
    [props.initialValues]
  );

  // NOTE: Debounce improves performance
  const onChangeFormValues = useCallback(
    debounce(({ values: formValues }) => {
      setValues(formValues);
    }, 200),
    [setValues]
  );

  return (
    <Form initialValues={stepInitialValues} {...props}>
      {fProps => (
        <>
          {typeof children === 'function' ? children(fProps) : children}
          {subscribeOnChange && (
            <FormSpy
              subscription={{ values: true, valid: true }}
              onChange={onChangeFormValues}
            />
          )}
        </>
      )}
    </Form>
  );
};

export default WizardForm;
