import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { cloneDeep, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid } from '@mui/material';
import { useTheme } from '@emotion/react';
import { SummaryDetails } from '../../../../components/SummaryDetails/index';
import * as STRINGS from '../../../../constants/strings';
import useAnalytics from '../../../../utils/useAnalytics';
import WizardForm from '../../../../features/Wizard/Form';
import * as OrderSelectors from '../../selectors';
import useBreakpoint from '../../../../hooks/useBreakpoint';
import { SHIPMENT_FIELDS } from '../../../../constants/forms';
import * as StepSelectors from '../ParcelDetails/selectors';
import { POINT_TYPE } from '../../../../constants';
import { useOverlay } from '../../../../features/Overlay/index';
import { BasketActions } from '../../../../redux/basket';
import { useCustomSnackbar } from '../../../../features/CustomSnackbar/index';

const CustomsDetails = ({
  nextStep,
  values,
  references,
  setValues,
  previousStep,
}) => {
  const isSmallScreen = useBreakpoint('md');
  const theme = useTheme();
  const overlay = useOverlay();
  const snackbar = useCustomSnackbar();
  const [searchParams] = useSearchParams();
  const { Trackable, trackAction } = useAnalytics();
  const dispatch = useDispatch();
  const price = useSelector(OrderSelectors.getTotalAmount);
  const selectedNetwork = useSelector(StepSelectors.getSelectedNetwork);
  const parcelsInvalid = useSelector(StepSelectors.validateParcelsValues);
  const { networks } = references;
  const collectFrom = get(values, SHIPMENT_FIELDS.COLLECT_FROM.KEY);
  const shipmentDate = get(values, SHIPMENT_FIELDS.SHIPMENT_DATE.KEY);
  const senderAddressDisabled = !get(
    values,
    SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY
  );
  const parcelSizeDisabled =
    senderAddressDisabled ||
    !get(values, SHIPMENT_FIELDS.COLLECTION_POSTCODE.KEY);
  const parcelDestinationDisabled =
    !networks?.length || parcelSizeDisabled || parcelsInvalid;
  const networkDisabled =
    parcelDestinationDisabled ||
    !collectFrom ||
    (collectFrom === POINT_TYPE.DOOR && !shipmentDate);
  const insuranceVisible = !!(
    selectedNetwork &&
    selectedNetwork.insurance &&
    !networkDisabled
  );

  const onSubmit = useCallback(
    async formData => {
      try {
        overlay.show();
        const values = cloneDeep(formData);
        const basketItemId = searchParams.get('basketItemId');

        const updatedBasketItem = {
          basketItemId: basketItemId,
          shipment: values,
        };

        await dispatch(
          BasketActions.updateBasketItem(updatedBasketItem)
        ).unwrap();

        nextStep(values);
      } catch (e) {
        snackbar.showError({
          message: STRINGS.FAILED_TO_SAVE_BASKET,
        });
      } finally {
        overlay.hide();
      }
    },
    [dispatch, nextStep, overlay, searchParams]
  );
  const handleBack = useCallback(
    event => {
      trackAction(event);
      setValues(values);
      previousStep();
    },
    [previousStep, setValues, values]
  );
  const handleNext = useCallback(
    event => {
      trackAction(event);
    },
    [trackAction, nextStep]
  );

  return (
    <Trackable loadId={{}}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <WizardForm onSubmit={onSubmit} validate={() => null}>
          {({ handleSubmit, values, invalid }) => (
            <>
              <Grid item xs={12} md={8}>
                <form id='customsDetailsForm' onSubmit={handleSubmit}>
                  Customs page
                </form>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{ position: 'sticky', top: theme.spacing(2) }}>
                  <SummaryDetails
                    price={price}
                    basketItemsWithPrice={[
                      {
                        shipment: values,
                        price,
                      },
                    ]}
                    displayItemsPriceDetails={!parcelSizeDisabled}
                    displayInsurance={insuranceVisible}
                  />
                  <Box
                    sx={{
                      gap: 1,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {!isSmallScreen && (
                      <Button
                        actionid={{}}
                        variant='outlined'
                        onClick={handleBack}
                        sx={{ flexGrow: 1 }}
                      >
                        {STRINGS.BACK}
                      </Button>
                    )}
                    <Button
                      actionid={{}}
                      variant='contained'
                      form='customsDetailsForm'
                      type='submit'
                      onClick={handleNext}
                      disabled={invalid}
                      sx={{ flexGrow: 1, marginX: { xs: 3, md: 0 } }}
                    >
                      {STRINGS.NEXT_STEP}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </>
          )}
        </WizardForm>
      </Grid>
    </Trackable>
  );
};

export default CustomsDetails;
