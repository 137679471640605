import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { COMMODITY_FINDER, SELECT } from '../../../constants/strings';
import { splitCode } from '../helpers/commodities';
import FaceThinkingIcon from '../../../components/icons/FaceThinkingIcon';

const CommodityList = ({ commodities, levels }) => {
  const theme = useTheme();

  const getBackgroundColor = level => {
    switch (level) {
      case 0:
        return theme.palette.primary.violet;
      case 1:
        return theme.palette.primary.blue;
      case 2:
        return theme.palette.primary.green;
      case 3:
        return theme.palette.primary.green;
      default:
        return 'transparent';
    }
  };

  const renderCommodity = (commodityCode, currentLevel = 0) => {
    const commodity = commodities[commodityCode];
    if (!commodity) return null;

    const isDisabled = !commodity.applyCode;
    const codePairs = splitCode(commodity.code);

    return (
      <Box
        key={commodityCode}
        sx={{
          mt: 2,
          padding: '10px',
          border: `1px solid ${theme.palette.primary.iconGray}`,
          marginLeft: `${currentLevel * 20}px`,
          borderRadius: '4px',
          color: isDisabled ? 'gray' : 'black',
          background: isDisabled
            ? theme.palette.primary.disabledBg
            : 'transparent',
          pointerEvents: isDisabled ? 'none' : 'auto',
        }}
      >
        <Typography variant='body2' sx={{ color: theme.palette.text.primary }}>
          {commodity.description}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
          {codePairs.map((pair, index) => (
            <Box
              key={index}
              sx={{
                backgroundColor: !isDisabled
                  ? getBackgroundColor(index)
                  : theme.palette.primary.gray_200,
                padding: '8px',
                margin: '4px',
                borderRadius: '4px',
                color: theme.palette.text.primary,
              }}
            >
              <Typography>{pair}</Typography>
            </Box>
          ))}
          {!isDisabled && (
            <Button sx={{ ml: 1 }} variant='outlined'>
              {SELECT}
            </Button>
          )}
        </Box>
      </Box>
    );
  };

  const renderLevels = () => {
    let previousLevel = 0;

    return levels.map((level, index) => {
      const levelParts = level.split('.').filter(Boolean);

      if (level.startsWith('..')) {
        return levelParts.map((commodityCode, idx) => {
          return (
            <Box key={`${commodityCode}-${index}-${idx}`} sx={{ mt: 2 }}>
              {renderCommodity(commodityCode, previousLevel)}
            </Box>
          );
        });
      } else {
        return levelParts.map((commodityCode, idx) => {
          const currentLevel = idx;
          previousLevel = currentLevel;
          return (
            <Box key={`${commodityCode}-${index}-${idx}`} sx={{ mt: 2 }}>
              {renderCommodity(commodityCode, currentLevel)}
            </Box>
          );
        });
      }
    });
  };

  return (
    <Box sx={{ paddingX: '8px', minHeight: '45vh', overflow: 'scroll' }}>
      {levels && levels.length > 0 ? (
        renderLevels()
      ) : (
        <Box sx={{ textAlign: 'center', mt: 5 }}>
          <FaceThinkingIcon />
          <Typography sx={{ mb: 2 }} variant='h3'>
            {COMMODITY_FINDER.NO_RESULT.TITLE}
          </Typography>
          <Box>
            <Typography variant='body2'>
              {COMMODITY_FINDER.NO_RESULT.SUBTITLE}
            </Typography>
            <Typography variant='body2'>
              {COMMODITY_FINDER.NO_RESULT.DESCRIPTION}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

CommodityList.propTypes = {
  commodities: PropTypes.object.isRequired,
  levels: PropTypes.array.isRequired,
};

export default CommodityList;
