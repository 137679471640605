import { cloneDeep, get } from 'lodash';
import { FORM, POINT_TYPE } from '../constants';
import { REQUIRED_TYPE } from '../constants/shipment';
import { SHIPMENT_FIELDS } from '../constants/forms';
import { AddressUtil } from '../utils';
import { convertObjectKeysToArray } from '../utils/object';

export const getReceiverContactName = shipment =>
  get(shipment, FORM.SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY, '');

export const isFlagRequired = (object, path) =>
  get(object, path, '') === REQUIRED_TYPE.MANDATORY;

export const isFlagNeedles = (object, path) =>
  get(object, path, '') === REQUIRED_TYPE.NEEDLESS;

export const isFlagOptional = (object, path) =>
  get(object, path, '') === REQUIRED_TYPE.OPTIONAL;

export const isFlagDescription = (object, path) =>
  get(object, path, '') === REQUIRED_TYPE.DESCRIPTION;

const defaultNavigationSteps = [
  {
    key: 'parcelDetails',
    label: 'Parcel',
  },
  {
    key: 'addressDetails',
    label: 'Details',
  },
  {
    key: 'payment',
    label: 'Payment',
  },
];

const customsDetails = {
  key: 'customsDetails',
  label: 'Customs',
};

export const isInvoiceAvailable = shipment => {
  const requiredTypes = [REQUIRED_TYPE.MANDATORY, REQUIRED_TYPE.OPTIONAL];
  const properties = [
    get(shipment, SHIPMENT_FIELDS.NETWORK_EXPORT_REASON_REQUIRED.KEY, ''),
    get(shipment, SHIPMENT_FIELDS.NETWORK_DT1_AVAILABLE.KEY, ''),
    get(shipment, SHIPMENT_FIELDS.NETWORK_DDP_AVAILABLE.KEY, ''),
    get(shipment, SHIPMENT_FIELDS.NETWORK_FDA_REQUIRED.KEY, ''),
    get(shipment, SHIPMENT_FIELDS.NETWORK_COST_REQUIRED.KEY, ''),
  ];
  const isProformaAvailable = requiredTypes.includes(
    get(shipment, SHIPMENT_FIELDS.NETWORK_PROFORMA.KEY, '')
  );
  const isInvoiceAvailable =
    isFlagRequired(
      shipment,
      SHIPMENT_FIELDS.NETWORK_EXPORT_IMPORT_REQUIRED.KEY
    ) && !isFlagNeedles(shipment, SHIPMENT_FIELDS.NETWORK_EORI_REQUIRED.KEY);

  return (
    isProformaAvailable &&
    (isInvoiceAvailable ||
      properties.includes(REQUIRED_TYPE.MANDATORY) ||
      properties.includes(REQUIRED_TYPE.OPTIONAL))
  );
};

export const isNiShipment = shipment =>
  AddressUtil.isNiShipment(
    get(shipment, SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY, ''),
    get(shipment, SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY, '')
  );

export const isAtRiskAvailable = values =>
  isInvoiceAvailable(values) &&
  get(values, SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY, false) &&
  isNiShipment(values);

export const isFdaNumberAvailable = values =>
  !isFlagNeedles(values, SHIPMENT_FIELDS.NETWORK_PROFORMA.KEY) &&
  !isFlagNeedles(values, SHIPMENT_FIELDS.NETWORK_FDA_REQUIRED.KEY);

export const isUkimsAvailable = values =>
  isNiShipment(values) &&
  get(values, SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY, false) &&
  !get(values, SHIPMENT_FIELDS.INVOICE_IMPORTER_AT_RISK.KEY, false);

export const getNavigationSteps = shipment => {
  const result = cloneDeep(defaultNavigationSteps);

  if (isInvoiceAvailable(shipment)) {
    result.splice(2, 0, customsDetails);
  }

  return result;
};

export const isShipmentDomestic = shipment =>
  AddressUtil.isCountryDomestic(
    get(shipment, SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY)
  );

export const isEoriRequired = values =>
  get(values, SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY, false) &&
  (isFlagRequired(values, SHIPMENT_FIELDS.NETWORK_EORI_REQUIRED.KEY) ||
    get(values, SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.KEY, ''));

export const isUkimsRequired = values => {
  const exporterUkimsNumber = get(
    values,
    SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.KEY,
    ''
  );
  const importerUkimsNumber = get(
    values,
    SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.KEY,
    ''
  );
  const isBusiness = get(
    values,
    SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY,
    false
  );
  const atRisk = get(
    values,
    SHIPMENT_FIELDS.INVOICE_IMPORTER_AT_RISK.KEY,
    false
  );

  return (
    !exporterUkimsNumber &&
    !importerUkimsNumber &&
    isNiShipment(values) &&
    isBusiness &&
    !atRisk &&
    isFlagRequired(values, SHIPMENT_FIELDS.NETWORK_UKIMS_NUMBER.KEY)
  );
};

export const getParcelDetailsRequiredFields = (
  values,
  references,
  countries
) => {
  const baseRequiredFields = [
    SHIPMENT_FIELDS.DELIVER_TO.KEY,
    SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY,
    SHIPMENT_FIELDS.DESTINATION_STREET.KEY,
    SHIPMENT_FIELDS.DESTINATION_CITY.KEY,

    SHIPMENT_FIELDS.COLLECT_FROM.KEY,
    SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY,
    SHIPMENT_FIELDS.COLLECTION_STREET.KEY,
    SHIPMENT_FIELDS.COLLECTION_CITY.KEY,

    SHIPMENT_FIELDS.NETWORK_CODE.KEY,
    SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY,
    SHIPMENT_FIELDS.NUMBER_OF_PARCELS.KEY,
  ];

  if (get(values, SHIPMENT_FIELDS.COLLECT_FROM.KEY) === POINT_TYPE.DOOR) {
    baseRequiredFields.push(SHIPMENT_FIELDS.SHIPMENT_DATE.KEY);
  }

  isPostcodeRequired(
    get(values, SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY),
    countries
  ) && baseRequiredFields.push(SHIPMENT_FIELDS.COLLECTION_POSTCODE.KEY);

  isPostcodeRequired(
    get(values, SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY),
    countries
  ) && baseRequiredFields.push(SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY);

  return baseRequiredFields;
};

export const getInvoiceRequiredFields = values => {
  if (!isInvoiceAvailable(values)) {
    return {};
  }

  const isBusiness = get(
    values,
    SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY,
    false
  );

  return {
    [SHIPMENT_FIELDS.INVOICE_EXPORTER_EORI_NUMBER.KEY]: true,
    [SHIPMENT_FIELDS.INVOICE_EXPORTER_FDA_NUMBER.KEY]: isFlagRequired(
      values,
      SHIPMENT_FIELDS.NETWORK_FDA_REQUIRED.KEY
    ),
    [SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.KEY]:
      isUkimsRequired(values),
    [SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY]: true,
    [SHIPMENT_FIELDS.INVOICE_IMPORTER_PID_NUMBER.KEY]:
      !isBusiness &&
      isFlagRequired(values, SHIPMENT_FIELDS.NETWORK_PID_NUMBER.KEY),
    [SHIPMENT_FIELDS.INVOICE_IMPORTER_VAT_NUMBER.KEY]:
      isBusiness &&
      isFlagRequired(values, SHIPMENT_FIELDS.NETWORK_VAT_NUMBER.KEY),
    [SHIPMENT_FIELDS.INVOICE_IMPORTER_EORI_NUMBER.KEY]: isEoriRequired(values),
    [SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.KEY]:
      isUkimsRequired(values),
    'invoice.exporterDetails': true,
    'invoice.importerDetails': true,
    invoice: true,
  };
};

export const getAddressDetailsRequiredFields = values => ({
  ...getInvoiceRequiredFields(values),
  [SHIPMENT_FIELDS.COLLECTION_CONTACT_NAME.KEY]: true,
  [SHIPMENT_FIELDS.COLLECTION_CONTACT_EMAIL.KEY]: true,
  [SHIPMENT_FIELDS.COLLECTION_CONTACT_TELEPHONE.KEY]: true,
  [SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY]: true,
  [SHIPMENT_FIELDS.DELIVERY_CONTACT_EMAIL.KEY]: true,
  [SHIPMENT_FIELDS.DELIVERY_CONTACT_MOBILE.KEY]: true,
  'outboundConsignment.deliveryDetails.notificationDetails': true,
  'outboundConsignment.deliveryDetails.contactDetails': true,
});

export const getFullShipmentRequiredFields = values => {
  const parcelDetailsRequiredFields = getParcelDetailsRequiredFields(values);
  const addressDetailsRequiredFields = convertObjectKeysToArray(
    getAddressDetailsRequiredFields(values)
  );

  return [...parcelDetailsRequiredFields, ...addressDetailsRequiredFields];
};

export const isPostcodeRequired = (countryKey, countriesArray) => {
  if (!countryKey || !countriesArray) {
    return false;
  }
  const country = countriesArray.find(c => c.countryKey === countryKey);
  return country?.isPostcodeRequired;
};
