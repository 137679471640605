export const normalizeAddressBookDataForPayload = ({ data }) => {
  const {
    countryCode,
    street,
    locality = '',
    town,
    county = '',
    postcode,
    organisation = '',
  } = data.address;

  const {
    contactName,
    email,
    phoneNumber,
    vatNumber = '',
    eoriNumber = '',
    pidNumber = '',
    ukimsNumber = '',
    isDefault = false,
    isBilling = false,
  } = data;

  return {
    countryCode,
    addressLine1: street,
    addressLine2: locality,
    addressLine3: town,
    addressLine4: county,
    postcode,
    contactName,
    organisation,
    email,
    phoneNumber,
    vatNumber,
    eoriNumber,
    pidNumber,
    ukimsNumber,
    isDefault,
    isBilling,
  };
};

// Filtering necessary fields only from the payload
export const normalizeAddressBookDataForCheckboxChangePayload = ({
  countryCode,
  addressLine1,
  addressLine2,
  addressLine3,
  addressLine4,
  postcode,
  contactName,
  organisation,
  email,
  phoneNumber,
  vatNumber,
  eoriNumber,
  pidNumber,
  ukimsNumber,
}) => ({
  countryCode,
  addressLine1,
  addressLine2,
  addressLine3,
  addressLine4,
  postcode,
  contactName,
  organisation,
  email,
  phoneNumber,
  vatNumber,
  eoriNumber,
  pidNumber,
  ukimsNumber,
});

export const normalizeAddressBookDataFromResponse = data => ({
  addressBookId: data?.addressBookId,
  isDefault: data?.isDefault,
  isBilling: data?.isBilling,
  address: {
    countryCode: data?.countryCode,
    street: data?.addressLine1,
    locality: data?.addressLine2,
    town: data?.addressLine3,
    county: data?.addressLine4,
    postcode: data?.postcode,
    organisation: data?.organisation,
  },
  contactName: data?.contactName,
  email: data?.email,
  phoneNumber: data?.phoneNumber,
  vatNumber: data?.vatNumber,
  eoriNumber: data?.eoriNumber,
  pidNumber: data?.pidNumber,
  ukimsNumber: data?.ukimsNumber,
});

export const normalizeFormValuesForValidation = ({ address, ...rest }) => {
  const {
    contactName,
    email,
    phoneNumber,
    vatNumber,
    eoriNumber,
    pidNumber,
    ukimsNumber,
  } = rest;

  return {
    address: {
      countryCode: address?.countryCode,
      street: address?.street,
      locality: address?.locality,
      town: address?.town,
      county: address?.county,
      postcode: address?.postcode,
      organisation: address?.organisation,
    },
    contactName,
    email,
    phoneNumber,
    vatNumber,
    eoriNumber,
    pidNumber,
    ukimsNumber,
  };
};

export const sortAddressBooks = (addressBooks = []) => {
  if (addressBooks.length === 0) {
    return addressBooks;
  }

  return addressBooks.sort((a, b) => {
    // First criterion: isDefault
    if (a.isDefault !== b.isDefault) {
      return a.isDefault ? -1 : 1; // Pin true values to the top
    }
    // Second criterion: isBilling
    if (a.isBilling !== b.isBilling) {
      return a.isBilling ? -1 : 1;
    }

    return a.contactName
      .toLowerCase()
      .localeCompare(b.contactName.toLowerCase());
  });
};
