export const SIGN_IN = '/signIn';
export const SIGN_IN_WITH_EMAIL = `${SIGN_IN}/email`;
export const SIGN_UP = '/signUp';
export const PROFILE = '/profile';
export const ACCOUNT = 'account';
export const ADDRESSES = 'addresses';
export const ORDERS = 'orders';
export const ORDER_VIEW = 'orders/:parcelNumber';

export const HOME = '/';
export const PICKUP_FINDER = '/pickup-finder';
export const RETURN = '/return';
export const TRACK = 'parcels';
export const TRACK_PARCEL_DETAILS = '/parcels/:parcelCode';
export const ORDER = '/order';
export const QUOTES = '/quotes';
export const SHIPMENT = '/shipments/:shipmentId';
export const ORDER_SUMMARY = '/orders/:orderId';
export const NOT_FOUND = '/404-not-found';
export const DONT_HAVE_ACCESS = '/dont-have-access';
export const BASKET = '/basket';
export const CONFIRM = 'confirm';
export const CONFIRM_LABEL = 'confirm/label';
export const CONFIRM_COLLECTION = 'confirm/collection';
export const EMAIL = 'email';
export const MAP_SAND_BOX = 'mapsandbox';
export const HELP = '/help';

export const DEV = 'dev';
export const DEV_PLAYGROUND = '/dev/playground';
