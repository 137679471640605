import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { DIMENSIONS_$ } from '../../../constants/strings';
import { Formatters } from '../../../utils';

const ParcelDetails = ({ parcel = {}, networkDesc, showMoreDetails }) => {
  const { _weight, _height, _width, _length, _quantity, _description, _value } =
    parcel;
  const parcelDescription = _description || 'Parcel';
  const isDimensionsAvailable = !!(_height && _length && _width && _value);
  const dimensionsDetails =
    isDimensionsAvailable && DIMENSIONS_$(_height, _width, _length, _value);
  const parcelQuantity = _quantity > 1 && `${_quantity} x`;
  const parcelWeight = _weight && `${_weight} kg`;

  const parcelQuantityWeightDescString = Formatters.joinStringsWith(
    [parcelQuantity, parcelWeight, parcelDescription],
    ' '
  );

  const parcelWeightDescDimensionsString = Formatters.joinStringsWith(
    [`${_weight}KG`, networkDesc, dimensionsDetails],
    ' | '
  );

  return (
    <Box sx={{ mt: 1, pr: 1 }}>
      {showMoreDetails ? (
        <>
          <Typography variant='body2' sx={{ wordBreak: 'break-word' }}>
            {parcelQuantityWeightDescString}
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            {dimensionsDetails}
          </Typography>
        </>
      ) : (
        <>
          <Typography
            variant='body2'
            color='text.secondary'
            sx={{ wordBreak: 'break-word' }}
          >
            {parcelDescription}
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            {Formatters.joinStringsWith(
              [parcelQuantity, parcelWeightDescDimensionsString],
              ' '
            )}
          </Typography>
        </>
      )}
    </Box>
  );
};

const ParcelsPriceDetails = ({
  receiverContactName,
  parcels,
  networkDesc,
  showMoreDetails,
  price,
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <Box>
      {receiverContactName && (
        <Box>
          <Typography variant='body2' sx={{ mb: 1, wordBreak: 'break-word' }}>
            {receiverContactName}
          </Typography>
        </Box>
      )}

      {parcels?.length &&
        parcels.map(parcel => (
          <ParcelDetails
            key={parcel.parcelId}
            parcel={parcel}
            networkDesc={networkDesc}
            showMoreDetails={showMoreDetails}
          />
        ))}
    </Box>
    <Typography variant='body2'>{price}</Typography>
  </Box>
);

ParcelsPriceDetails.propTypes = {
  receiverContactName: PropTypes.string,
  parcels: PropTypes.arrayOf(PropTypes.object),
  networkDesc: PropTypes.string,
  showMoreDetails: PropTypes.bool,
  price: PropTypes.object,
};

export default ParcelsPriceDetails;
