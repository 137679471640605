import { isBoolean } from 'lodash';

export const phoneNumberNormalize = value => {
  if (!value) {
    return value;
  }

  let phoneValue = value?.replace(/[^+\d]/g, '');
  const plusCharacterIndex = phoneValue?.lastIndexOf('+');

  if (plusCharacterIndex !== -1 && plusCharacterIndex !== 0) {
    phoneValue =
      phoneValue?.slice(0, plusCharacterIndex) +
      phoneValue?.slice(plusCharacterIndex + 1);
  }

  return phoneValue.substring(0, 15);
};

export const formatAndConstrainDecimal = ({
  value,
  maxIntegerLength = 2,
  maxDecimalLength = 2,
  minValue = 1,
}) => {
  const numericValue = value.replace(/[^0-9.]/g, '');
  const [integerPart, decimalPart] = numericValue.split('.');

  if (decimalPart !== undefined) {
    const fieldValue = `${integerPart.slice(
      0,
      maxIntegerLength
    )}.${decimalPart.slice(0, maxDecimalLength)}`;
    if (
      decimalPart.length >= maxDecimalLength &&
      Number(fieldValue) < minValue
    ) {
      return minValue.toString();
    }
    return fieldValue;
  }
  return integerPart.slice(0, maxIntegerLength);
};

const TRUTHY = ['true', 'y', 1, '1', 'a'];
const FALSY = ['false', 'n', 0, '0', 'b'];

export const convertToBool = (value, forceCust = false) => {
  const normalizedValue =
    typeof value === 'string' ? value.toLowerCase() : value;

  if (TRUTHY.includes(normalizedValue)) {
    return true;
  }
  if (FALSY.includes(normalizedValue)) {
    return false;
  }

  return forceCust ? null : value;
};

export const booleanOrNullValueNormalize = value => convertToBool(value, true);

export const booleanOrNullValueFormat = value => {
  const castValue = convertToBool(value, false);
  if (isBoolean(castValue)) {
    return castValue ? '1' : '0';
  }
  return '';
};
