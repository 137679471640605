export const EXCLUDE_REASONS = [
  '1^19',
  '2^19',
  '1^79',
  '2^79',
  '1^39',
  '2^39',
  '1^89',
  '2^89',
];

export const DEFAULT_REASONS = [
  {
    label: 'Sale',
    value: '01',
  },
  {
    label: 'Gift',
    value: '03',
  },
];
