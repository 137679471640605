import { useState, useMemo, useCallback } from 'react';
import { Box, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SHARE_TRACKING_INFO } from '../../../constants/strings';
import { TRACK as TRACK_PATH } from '../../../constants/routes';
import OrderDetails from '../../../components/OrderDetails';
import EmptySearchResults from '../../../components/EmptySearchResults';
import Loader from '../../../features/Loader';
import useParcelSearch from '../../../hooks/useParcelSearch';
import useGetParcelParams from '../../../hooks/useGetParcelParams';
import {
  ERROR_BOUNDARY_TYPES,
  ErrorBoundary,
  useErrorBoundaryConfigurations,
} from '../../../features/ErrorBoundary';
import ShareModal from '../../../components/ShareModal/ShareModal';
import parcelSlice from '../../../redux/parcelSlice';
import useBreakpoint from '../../../hooks/useBreakpoint';

const TrackSection = () => {
  const navigate = useNavigate();
  const navigateToTrack = () => navigate(TRACK_PATH);
  const isSmallScreen = useBreakpoint('md');

  const { parcelCode, parcelNumber, postcode } = useGetParcelParams();

  const { data: parcel } = useSelector(
    parcelSlice.selectors.getParcel(parcelNumber)
  );

  const { fetchParcel, isParcelFound } = useParcelSearch();
  const getErrorBoundaryConfig = useErrorBoundaryConfigurations();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const watchParams = useMemo(
    () => [parcelNumber, postcode],
    [parcelNumber, postcode]
  );

  const fetchParcelFn = useCallback(
    () => fetchParcel({ parcelCode, postcode }),
    [fetchParcel, parcelCode, postcode]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignSelf: isParcelFound ? 'baseline' : 'center',
        flexGrow: 1,
        width: '100%',
      }}
    >
      <Loader
        promiseFn={fetchParcelFn}
        pendingProps={{
          sx: {
            display: 'flex',
            justifyContent: 'center',
            flexGrow: 1,
            alignItems: 'center',
            p: 2,
            mt: { xs: -4, md: 0 },
          },
        }}
        watch={watchParams}
        errorComponent={error => (
          <ErrorBoundary
            config={getErrorBoundaryConfig[
              ERROR_BOUNDARY_TYPES.WE_HAVE_A_LITTLE_PROBLEM
            ](error.message)}
          />
        )}
      >
        {isParcelFound ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexGrow: 1,
              p: 2,
            }}
          >
            <ShareModal
              parcel={parcel}
              open={isShareModalOpen}
              onClose={() => setIsShareModalOpen(false)}
            />
            <OrderDetails
              parcel={parcel}
              showBackButton={isSmallScreen}
              footerButtons={
                <Button
                  variant='outlined'
                  size='medium'
                  onClick={() => setIsShareModalOpen(true)}
                >
                  {SHARE_TRACKING_INFO}
                </Button>
              }
              onBackClick={navigateToTrack}
            />
          </Box>
        ) : (
          <EmptySearchResults sx={{ mt: { xs: 0, md: 5 }, px: 2 }} />
        )}
      </Loader>
    </Box>
  );
};

export default TrackSection;
