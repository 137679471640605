import { Alert, TextField, Autocomplete } from '@mui/material';
import { scrollIntoView } from 'seamless-scroll-polyfill';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import Sentry from '../../sentry';
import { ADDRESS_LOOKUP } from '../../constants/strings';
import { fastFind, retrieve } from '../../apis/address';
import { removeExtraSpaces } from '../../utils/strings';
import useBreakpoint from '../../hooks/useBreakpoint';

const AddressLookup = ({
  onChange,
  addressKey,
  countryISO,
  inputSize,
  autocompleteInputId,
  disabled,
  ...props
}) => {
  const [lookupResults, setLookupResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [value, setValue] = useState({
    postcode: '',
    list: '',
  });
  const isSmallScreen = useBreakpoint('sm');
  const autocompleteRef = useRef(null);
  const updateLookupResults = (addressResults = []) => {
    const parsedAddressResults = addressResults.map(addressResult => ({
      key: addressResult.Key,
      list: removeExtraSpaces(addressResult.List),
      postcode: addressResult.Postcode,
    }));

    setLookupResults(parsedAddressResults);
  };

  const onLookupChange = async event => {
    const lookup = event.target.value;

    debouncedFastFindLookup(lookup);
  };

  const errorHandler = (message, error) => {
    setErrorMessage(message);

    Sentry.captureException(error, { component: 'AddressLookup', message });
  };

  const fastFindLookup = useCallback(
    async lookup => {
      const MIN_LOOKUP_LENGTH = 3;

      if (lookup.length >= MIN_LOOKUP_LENGTH) {
        setErrorMessage('');
        try {
          const result = await fastFind({ lookup, countryISO });

          updateLookupResults(result.Item);
        } catch (error) {
          errorHandler(ADDRESS_LOOKUP.LOOKUP_ERROR, error);
        }
      } else {
        // Reset results
        setLookupResults([]);
      }
    },
    [countryISO]
  );

  const debounceWaitTime = 300;

  // Debounced version of the fastFindLookup
  const debouncedFastFindLookup = useMemo(
    () => debounce(fastFindLookup, debounceWaitTime),
    [fastFindLookup]
  );

  const cleanup = () => {
    setValue({
      key: '',
      list: '',
      postcode: '',
    });

    setLookupResults([]);
  };

  const handleFocus = useCallback(() => {
    autocompleteRef &&
      isSmallScreen &&
      scrollIntoView(
        autocompleteRef.current,
        { behavior: 'smooth', block: 'start' },
        { duration: 250 }
      );
  }, [isSmallScreen]);

  useEffect(() => {
    if (addressKey) {
      selectAddress(addressKey);
    } else if (addressKey === '') {
      cleanup();
    }
  }, [addressKey]);

  const selectAddress = async (key, isOptionSelectedManually = false) => {
    const fullAddressData = await retrieve({ key, countryISO });
    const fullAddress = fullAddressData?.Item?.[0];

    if (fullAddress) {
      setValue({
        key: fullAddress.Key,
        list: removeExtraSpaces(fullAddress.List),
        postcode: fullAddress.Postcode,
      });

      onChange({ ...fullAddress, isOptionSelectedManually });
      debouncedFastFindLookup(fullAddress.Postcode);
    }
  };

  return (
    <>
      {errorMessage ? <Alert severity='error'>{errorMessage}</Alert> : null}

      <Autocomplete
        disableClearable
        options={lookupResults}
        getOptionLabel={option => option.list}
        filterOptions={options => options}
        size={inputSize}
        renderInput={params => (
          <TextField
            label={ADDRESS_LOOKUP.LABEL}
            placeholder={ADDRESS_LOOKUP.LOOKUP_PLACEHOLDER}
            onChange={onLookupChange}
            {...props}
            {...params}
            inputProps={{
              ...params.inputProps,
              'data-testid': autocompleteInputId,
            }}
          />
        )}
        onInputChange={(_, newInputValue) => {
          // Cleanup input
          if (newInputValue === '') {
            cleanup();
          }
        }}
        onChange={(_, selectedAddress) => {
          if (selectedAddress?.key) {
            selectAddress(selectedAddress.key, true);
          } else {
            onChange(undefined);

            cleanup();
          }
        }}
        onFocus={handleFocus}
        value={value}
        isOptionEqualToValue={(option, value) => option.key === value.key}
        disabled={!countryISO || disabled}
        ref={autocompleteRef}
      />
    </>
  );
};

AddressLookup.propTypes = {
  onChange: PropTypes.func,
  addressKey: PropTypes.string,
  countryISO: PropTypes.string,
  inputSize: PropTypes.string,
  autocompleteInputId: PropTypes.string,
};

export default AddressLookup;
