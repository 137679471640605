import Close from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import {
  Box,
  IconButton,
  Divider,
  Modal,
  Typography,
  Grid,
  FormControl,
  TextField,
  Button,
} from '@mui/material';
import { COMMODITY_FINDER } from '../../constants/strings';
import { DEFAULT_PAGINATION_SEARCH_MODAL_SIZE } from '../../constants/pagination';
import useErrorHandler from '../../hooks/useErrorHandler';
import { FIELDS } from '../../constants/forms';
import { Field, Form } from 'react-final-form';
import { getCommodities } from '../../apis/commodities';
import CommodityList from './components/CommodityList';

const CommodityFinderModal = ({ open, onClose }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [commodities, setCommodities] = useState();
  const [levels, setLevels] = useState();
  const { errorHandler } = useErrorHandler();
  const formRef = useRef(null);
  const [searchValue, setSearchValue] = useState('');

  const searchPageSize = DEFAULT_PAGINATION_SEARCH_MODAL_SIZE;

  const performSearch = async searchString => {
    try {
      const args = {
        searchPage: currentPage,
        searchPageSize,
        searchString: searchString.trim(),
      };

      const data = await getCommodities(args);

      setCommodities(data.commodities);
      setLevels(data.levels);
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleFormSubmit = values => {
    const { commodity } = values;
    if (commodity) {
      performSearch(commodity);
    }
  };

  const clearInput = () => {
    setSearchValue('');
    setCommodities();
    setLevels();
  };

  useEffect(() => {
    if (open) {
      setCurrentPage(1);
    }
  }, [open]);

  useEffect(() => {
    if (currentPage > 1) {
      formRef.current && formRef.current.submit();
    }
  }, [currentPage]);

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setCurrentPage(1);
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'primary.contrastText',
          position: 'relative',
          m: { sm: '10% auto' },
          borderRadius: 1,
          maxWidth: { sm: 600 },
          height: {
            xs: '100%',
            sm: 'auto',
          },
          maxHeight: {
            md: '75%',
          },
          overflowY: {
            xs: 'scroll',
            sm: 'auto',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant='h3' sx={{ p: 2 }}>
            {COMMODITY_FINDER.TITLE}
          </Typography>
          <IconButton
            aria-label='close'
            sx={{ mr: 0.5 }}
            onClick={() => {
              onClose();
              setCurrentPage(1);
            }}
          >
            <Close />
          </IconButton>
        </Box>

        <Divider />

        <Box sx={{ padding: 2 }}>
          <Form
            onSubmit={handleFormSubmit}
            ref={formRef}
            initialValues={{ commodity: '' }}
            render={({ handleSubmit }) => (
              <Box
                component='form'
                autoComplete='off'
                onSubmit={handleSubmit}
                sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}
              >
                <FormControl
                  fullWidth
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <Field name={FIELDS.COMMODITY.KEY}>
                    {({ input, meta }) => (
                      <>
                        <TextField
                          id='commodity-code'
                          label={FIELDS.COMMODITY.LABEL}
                          placeholder={FIELDS.COMMODITY.PLACEHOLDER}
                          fullWidth
                          size='medium'
                          error={!!(meta.touched && meta.error)}
                          {...input}
                          value={searchValue}
                          helperText={
                            meta.touched && meta.error
                              ? meta.error
                              : COMMODITY_FINDER.SUBTITLE
                          }
                          onChange={e => {
                            input.onChange(e);
                            setSearchValue(e.target.value);
                            if (e.target.value.trim() === '') {
                              setCommodities();
                              setLevels();
                            }
                          }}
                          sx={{
                            borderRadius: '4px',
                            height: '40px',
                            mr: 1,
                            '& .MuiInputBase-root': {
                              height: '40px',
                            },
                            '& .MuiOutlinedInput-root': {
                              padding: '0',
                            },
                            '& .MuiInputLabel-root': {
                              top: '-6px',
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <IconButton onClick={clearInput}>
                                <ClearIcon />
                              </IconButton>
                            ),
                          }}
                        />
                        <Button
                          variant='contained'
                          sx={{
                            height: '40px',
                          }}
                          type='submit'
                        >
                          {COMMODITY_FINDER.SEARCH_BUTTON}
                        </Button>
                      </>
                    )}
                  </Field>
                </FormControl>
              </Box>
            )}
          />
        </Box>

        <Divider />

        <Box sx={{ padding: 2 }}>
          <CommodityList commodities={commodities} levels={levels} />
        </Box>

        <Grid
          container
          justifyContent='center'
          sx={{ padding: '8px 16px 16px', position: 'relative' }}
        >
          <Divider sx={{ position: 'absolute', width: '100%', top: '0' }} />
        </Grid>
      </Box>
    </Modal>
  );
};

CommodityFinderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CommodityFinderModal;
