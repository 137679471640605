import { GB } from './strings';

export const ADDRESS_BOOK_INITIAL_FORM_VALUES = {
  address: {
    countryCode: GB,
    postcode: '',
    organisation: '',
    county: '',
    town: '',
    street: '',
    locality: '',
  },
  contactName: '',
  email: '',
  phoneNumber: '',
  vatNumber: '',
  eoriNumber: '',
  pidNumber: '',
  ukimsNumber: '',
  isDefault: false,
  isBilling: false,
};
