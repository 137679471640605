import { createSelector } from '@reduxjs/toolkit';
import {
  OrderPriceAccumulator,
  ShipmentPrice,
} from '@dpdgroupuk/consumer-shipping-helper';
import { get } from 'lodash';

import { SHIPMENT_FIELDS } from '../../constants/forms';
import configSlice from '../../redux/configSlice';
import { BasketSelectors } from '../../redux/basket';
import { ShipmentHelpers } from '../../helpers';
import { getPriceData } from '../../utils/networks';
import { OrderSliceSelectors } from './slice';

export const getTotalAmount = createSelector(
  [
    OrderSliceSelectors.getReferences,
    OrderSliceSelectors.getValues,
    configSlice.selectors.getPriceConfig,
  ],
  (references, shipment, config) => {
    const network = references.networks.find(
      n => n.networkKey === get(shipment, SHIPMENT_FIELDS.NETWORK_CODE.KEY)
    );

    if (!network) {
      const price = new ShipmentPrice().toViewJSON(2);

      return getPriceData({
        price,
        networks: references.networks,
        shipment,
        config,
      });
    }
    const accumulator = OrderPriceAccumulator.aggregate(
      new OrderPriceAccumulator(),
      'new',
      ShipmentPrice.calculateShipmentPrice(shipment, network.price, config)
    );
    if (references.voucher) {
      accumulator.applyVoucherDiscount(references.voucher);
    }
    const price = accumulator.toViewJSON(2);

    return getPriceData({
      price,
      networks: references.networks,
      shipment,
      config,
    });
  }
);

export const getNavigationSteps = createSelector(
  [BasketSelectors.getBasketItemById, (state, basketItemId) => basketItemId],
  basketItem => ShipmentHelpers.getNavigationSteps(basketItem?.shipment)
);
