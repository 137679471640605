import Joi from 'joi';
import {
  HEIGHT,
  LENGTH,
  QUANTITY,
  VALUE,
  WEIGHT,
  WIDTH,
} from '../../../constants/forms';
import { PARCEL_DESCRIPTION } from '../../../constants/strings';

const dimensionValidator = Joi.number().greater(0).max(100).messages({
  'number.max': 'Size too long',
});

export const maxTotalParcelsValue = 99999999.99;
export const minParcelValue = 0.01;

export const parcelSchema = ({ maxWeight = 30, maxParcelNumber = 99 }) =>
  Joi.object({
    parcelId: Joi.string(),
    _weight: Joi.number()
      .greater(0)
      .max(maxWeight)
      .label(WEIGHT)
      .required()
      .messages({
        'number.max': `{#label} must be less than or equal to ${maxWeight}`,
      }),
    _quantity: Joi.number()
      .min(1)
      .max(maxParcelNumber)
      .label(QUANTITY)
      .required()
      .messages({
        'number.max': `{#label} must be less than or equal to ${maxParcelNumber}`,
      }),
    _width: dimensionValidator.label(WIDTH).required(),
    _height: dimensionValidator.label(HEIGHT).required(),
    _length: dimensionValidator.label(LENGTH).required(),
    _value: Joi.number()
      .required()
      .min(minParcelValue)
      .max(maxTotalParcelsValue)
      .required()
      .messages({
        'number.min': `Min value is ${minParcelValue}`,
        'number.max': `Max value is ${maxTotalParcelsValue}`,
      })
      .label(VALUE),
    _description: Joi.string()
      .allow('', null)
      .max(45)
      .label(PARCEL_DESCRIPTION),
    products: Joi.array().items(Joi.object()).allow(null),
  });
