import Joi from 'joi';
import moment from 'moment-timezone';
import {
  CONTACT_NAME,
  CUSTOMS_VALUE,
  EMAIL,
  FIELDS,
  MOBILE_NUMBER,
  PHONE_NUMBER,
  QUANTITY,
  REASON_FOR_EXPORT,
  SHIPMENT_FIELDS,
} from '../../../constants/forms';
import { POINT_TYPE, REGEX, STRINGS } from '../../../constants';
import { addressSchemaWithPostCode } from '../../../utils/validators';
import { StringUtil } from '../../../utils';
import { maxTotalParcelsValue, parcelSchema } from './parcel';

const empty = [null, ''];

const emptyString = Joi.string().allow(...empty);
const numberOfParcels = Joi.number().min(1).max(99).label(QUANTITY);
const totalWeight = Joi.number().greater(0);
const telephone = emptyString
  .pattern(REGEX.COMMON_PHONE_NUMBER_REGEXP)
  .max(15)
  .messages({
    'string.pattern.base': StringUtil.formatMessage(
      STRINGS.PLEASE_ENTER_VALID_$,
      STRINGS.PHONE_NUMBER
    ),
  })
  .label(PHONE_NUMBER);

export const addressDetailsSchema = countryValue =>
  Joi.object({
    [FIELDS.ADDRESS_KEY.KEY]: emptyString,
    ...addressSchemaWithPostCode(countryValue),
    _coordinates: Joi.object({
      latitude: Joi.number(),
      longitude: Joi.number(),
    }).allow(null),
  }).unknown();

export const contactDetails = () =>
  Joi.object({
    contactName: emptyString.max(35).label(CONTACT_NAME),
    telephone,
    email: emptyString
      .pattern(REGEX.EMAIL_REGEX)
      .max(100)
      .messages({
        'string.pattern.base': StringUtil.formatMessage(
          STRINGS.PLEASE_ENTER_VALID_$,
          STRINGS.EMAIL
        ),
      })
      .label(EMAIL),
  });

export const email = () =>
  Joi.string().max(100).regex(REGEX.EMAIL_REGEX).label(EMAIL);

export const notificationDetails = () =>
  Joi.object().keys({
    mobile: telephone,
    email: emptyString
      .pattern(REGEX.EMAIL_REGEX)
      .max(100)
      .messages({
        'string.pattern.base': StringUtil.formatMessage(
          STRINGS.PLEASE_ENTER_VALID_$,
          STRINGS.EMAIL
        ),
      })
      .label(EMAIL),
  });

export const pointType = () =>
  Joi.string().valid(POINT_TYPE.SHOP, POINT_TYPE.DOOR);

const baseImporterExporterField = Joi.string().min(4).max(45);

// TODO: add ioss validation
export const invoiceSchema = Joi.object({
  exportReason: Joi.string().label(REASON_FOR_EXPORT),
  importerDetails: Joi.object({
    vatNumber: baseImporterExporterField.label(
      SHIPMENT_FIELDS.INVOICE_IMPORTER_VAT_NUMBER.LABEL
    ),
    pidNumber: baseImporterExporterField.label(
      SHIPMENT_FIELDS.INVOICE_IMPORTER_PID_NUMBER.LABEL
    ),
    eoriNumber: baseImporterExporterField.label(
      SHIPMENT_FIELDS.INVOICE_IMPORTER_EORI_NUMBER.LABEL
    ),
    ukimsNumber: Joi.string()
      .length(32)
      .example('11111111111111111111111111111111')
      .label(SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.LABEL),
    isBusiness: Joi.boolean(),
    atRisk: Joi.boolean(),
  }).label(STRINGS.IMPORTER_DETAILS),
  exporterDetails: Joi.object({
    vatNumber: baseImporterExporterField.label(
      SHIPMENT_FIELDS.INVOICE_EXPORTER_VAT_NUMBER.LABEL
    ),
    eoriNumber: Joi.string()
      .regex(REGEX.BUSINESS_EORI_REGEX)
      .messages({
        'string.pattern.base': StringUtil.formatMessage(
          STRINGS.PLEASE_ENTER_VALID_$,
          STRINGS.EORI
        ),
      })
      .label(SHIPMENT_FIELDS.INVOICE_EXPORTER_EORI_NUMBER.LABEL),
    fdaRegistrationNumber: Joi.string()
      .max(30)
      .label(SHIPMENT_FIELDS.INVOICE_EXPORTER_FDA_NUMBER.LABEL),
    ukimsNumber: Joi.string()
      .length(32)
      .example('11111111111111111111111111111111')
      .label(SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.LABEL),
    isBusiness: Joi.boolean(),
  }).label(STRINGS.EXPORTER_DETAILS),
});

export const shipmentSchema = ({
  deliveryCountryValue,
  collectionCountryValue,
  maxWeight,
  maxParcelNumber,
}) =>
  Joi.object({
    shipmentDate: Joi.string()
      .allow(null)
      .when(SHIPMENT_FIELDS.COLLECT_FROM.KEY, {
        is: POINT_TYPE.DOOR,
        then: Joi.custom((value, helpers) => {
          if (value) {
            const date = moment(value);
            const now = moment();

            if (date.isBefore(now)) {
              return helpers.error('date.invalid');
            }
          }
          return value;
        }),
      })
      .messages({
        'date.invalid': STRINGS.DATE_CANNOT_BE_IN_THE_PAST,
      }),
    outboundConsignment: Joi.object({
      deliveryDetails: {
        address: addressDetailsSchema(deliveryCountryValue),
        contactDetails: contactDetails()
          .keys({
            _addressBookId: emptyString,
            email: email().allow(...empty),
          })
          .allow(null)
          .label(STRINGS.RECEIVER_CONTACT_DETAILS),
        notificationDetails: notificationDetails()
          .allow(null)
          .label(STRINGS.RECEIVER_NOTIFICATION_DETAILS),
        _deliverTo: pointType(),
      },

      collectionDetails: {
        address: addressDetailsSchema(collectionCountryValue),
        contactDetails: contactDetails()
          .keys({
            _addressBookId: emptyString,
          })
          .allow(null),
        dropAtShop: Joi.boolean().allow(null),
        _collectFrom: pointType(),
      },

      pickupDetails: Joi.object({
        pickupLocationCode: Joi.string().max(20),
        pickupCountryCode: Joi.string().max(2).regex(REGEX.COUNTRY_CODE),
        pickupOrganisation: emptyString.max(35),
        pickupPostcode: emptyString,
        pickupAddressLine1: emptyString.max(35),
        pickupAddressLine2: emptyString.max(35),
        pickupAddressLine3: emptyString.max(35),
        pickupAddressLine4: emptyString.max(35),
        allowRemotePickup: Joi.boolean().allow(null),
      }).allow(null),

      _dropOffDetails: Joi.object({
        pickupLocationCode: Joi.string().max(20),
        pickupCountryCode: Joi.string().max(2).regex(REGEX.COUNTRY_CODE),
        pickupOrganisation: emptyString.max(35),
        pickupPostcode: emptyString,
        pickupAddressLine1: emptyString.max(35),
        pickupAddressLine2: emptyString.max(35),
        pickupAddressLine3: emptyString.max(35),
        pickupAddressLine4: emptyString.max(35),
      }).allow(null),
      numberOfParcels,
      totalWeight,
      parcelDescription: emptyString.max(45),
      networkCode: Joi.string(),
      liability: Joi.boolean(),
      liabilityValue: Joi.number(),
      currency: emptyString
        .length(3)
        .label(SHIPMENT_FIELDS.CURRENCY.LABEL)
        .example('GBP'),
      customsValue: Joi.number()
        .greater(0)
        .max(maxTotalParcelsValue)
        .precision(2)
        .label(CUSTOMS_VALUE)
        .example('333.3')
        .allow(...empty),
      parcels: Joi.array().items(parcelSchema({ maxWeight, maxParcelNumber })),
      // shippingRef1: Joi.string().max(25),
    }),
    invoice: invoiceSchema.allow(null).label(STRINGS.INVOICE),
  });

export const verifyPhoneSchema = Joi.object({
  phoneNumber: emptyString
    .pattern(REGEX.GB_PHONE_NUMBER_REGEXP)
    .required()
    .messages({
      'string.pattern.base': StringUtil.formatMessage(
        STRINGS.PLEASE_ENTER_VALID_$,
        STRINGS.MOBILE_NUMBER
      ),
    })
    .label(MOBILE_NUMBER),
});

export const verifyPinSchema = Joi.object({
  pin: Joi.string().min(6).max(6).required(),
});
