import { HttpStatusCode } from 'axios';
import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import parcelSlice from '../redux/parcelSlice';

import { FETCHING_PARCEL_ERROR } from '../constants/strings';
import useErrorHandler from './useErrorHandler';

const useParcelSearch = () => {
  const { errorHandler } = useErrorHandler();
  const dispatch = useDispatch();

  const [isParcelFound, setIsParcelFound] = useState();

  const fetchParcel = useCallback(
    async ({ parcelNumber, parcelCode, postcode }) => {
      if (!parcelNumber && !parcelCode) {
        return;
      }

      try {
        const action = parcelNumber
          ? parcelSlice.actions.fetchParcel(parcelNumber)
          : parcelSlice.actions.fetchParcelByCode({ parcelCode, postcode });

        await dispatch(action).unwrap();
        setIsParcelFound(true);
      } catch (error) {
        if (error.statusCode === HttpStatusCode.NotFound) {
          setIsParcelFound(false);

          return;
        }

        errorHandler(FETCHING_PARCEL_ERROR, error);

        throw new Error(error.message);
      }
    },
    [dispatch]
  );

  return { fetchParcel, isParcelFound };
};

export default useParcelSearch;
