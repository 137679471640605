/* eslint-disable max-lines */
/* eslint-disable complexity */
import { v4 as uuidv4 } from 'uuid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Field } from 'react-final-form';
import { OnBlur, OnChange } from 'react-final-form-listeners';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, MenuItem, Typography } from '@mui/material';
import { TextField as TextFieldRFF } from 'mui-rff';
import { Add, Delete } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import { cloneDeep, debounce, filter, find, get, isNil } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { useDeepCompareEffect } from 'react-use';

import Debugger from '../../../../components/Debugger/Debugger';
import * as STRINGS from '../../../../constants/strings';
import { PARCEL_DETAILS } from '../../../../constants/analytics';
import { SummaryDetails } from '../../../../components/SummaryDetails';
import { countriesSelectors } from '../../../../redux/countriesSlice';
import {
  COLLECTION_DETAILS_KEY,
  DELIVERY_DETAILS_KEY,
  FIELDS,
  SHIPMENT_FIELDS,
} from '../../../../constants/forms';
import ActionButton from '../../../../components/ActionButton';
import MaxParcelDimsModal from '../../../../components/MaxParcelDimsModal/MaxParcelDimsModal';
import PickupModal from '../../../../components/PickupModal';
import AddressDetailsForm from '../../../../components/AddressDetailsForm/AddressDetailsForm';
import useBreakpoint from '../../../../hooks/useBreakpoint';
import PickupPointWidget from '../../../../components/PickupPointwidget/PickupPointWidget';
import { BasketActions } from '../../../../redux/basket';
import { POINT_TYPE } from '../../../../constants';
import { useOverlay } from '../../../../features/Overlay';
import { useCustomSnackbar } from '../../../../features/CustomSnackbar';
import { PickupHelper, ShipmentHelper } from '../../helpers';
import { useReferencesLoader } from '../../hooks';
import { INSURANCE_SECTION, REFERENCE_NAME } from '../../constants';
import { DATE_TIME_FORMAT } from '../../../../constants/dateFormat';
import AddressBookModal from '../../../../components/AddressBookModal';
import * as OrderSelectors from '../../selectors';
import configSlice from '../../../../redux/configSlice';
import useErrorHandler from '../../../../hooks/useErrorHandler';
import Loader from '../../../../features/Loader';
import * as AppActions from '../../../../redux/appActions';
import { NETWORK_DETAILS, NETWORK_KEYS } from '../../../../constants/shipment';
import { getDefaultShipmentDate } from '../../helpers/shipment';
import {
  maxTotalParcelsValue,
  minParcelValue,
  parcelSchema,
} from '../../validators/parcel';
import {
  AddressUtil,
  ArrayUtil,
  Formatters,
  FormUtil,
  NetworkUtil,
  Normalizers,
  StringUtil,
  useAnalytics,
  Validators,
} from '../../../../utils';
import {
  PICKUP_POINT_TYPE,
  PICKUP_POINT_TYPE_VALUE,
} from '../../../../constants/pickupPoint';
import {
  TOTAL_QUANTITY_ERROR,
  TOTAL_VALUE_ERROR,
} from '../../../../constants/error';
import OrderForm from '../../components/OrderForm';
import * as StepSelectors from './selectors';
import { SECTION, SECTION_FIELDS_MAP } from './constants';
import FormSection from './components/FormSection';
import { getFieldsToUpdate } from './helpers';

const ParcelDetails = ({ nextStep, values, references, setReferences }) => {
  const navigate = useNavigate();
  const { setInterfaceId, Trackable } = useAnalytics();
  const theme = useTheme();
  const overlay = useOverlay();
  const isSmallScreen = useBreakpoint('md');
  const [searchParams, setSearchParams] = useSearchParams();
  const snackbar = useCustomSnackbar();
  const referencesLoader = useReferencesLoader();
  const [recommended, setRecommended] = useState({});
  const [collectionDates, setCollectionDates] = useState([]);

  const quantitySymbolsLengthLimit = 2;
  const valueSymbolsLengthLimit = 8;
  const weightFieldConfig = {
    maxIntegerLength: 2,
    maxDecimalLength: 2,
    minValue: 0.1,
  };
  const totalQuantityLimit = 99;

  const { networks } = references;
  const filteredNetworks = useSelector(StepSelectors.getFilteredNetworks);
  const selectedNetwork = useSelector(StepSelectors.getSelectedNetwork);
  const parcelsInvalid = useSelector(StepSelectors.validateParcelsValues);
  const shipToShopAvailable = useSelector(StepSelectors.getShipToShopAvailable);
  const networksQuery = useSelector(StepSelectors.getOutboundNetworkParameters);
  const defaultCutOff = useSelector(
    configSlice.selectors.getCollectionCutOffConfig
  );
  const insuranceConfig = useSelector(configSlice.selectors.getInsuranceConfig);
  const freeInsurance = insuranceConfig[0];
  const recommendedInsurance = ArrayUtil.findInArray({
    array: insuranceConfig,
    key: 'insuranceValue',
    value: Number(Object.keys(recommended)[0]),
  });

  const countries = useSelector(countriesSelectors.getCountries);

  const dispatch = useDispatch();
  const { errorHandler } = useErrorHandler();
  const [maxDimsModalOpen, setMaxDimsModalOpen] = useState(false);

  useEffect(() => {
    setInterfaceId(PARCEL_DETAILS.INTERFACE_ID);
    overlay.show();

    const loadReferences = async () => {
      try {
        await Promise.all([
          referencesLoader.loadDropOffCollection(),
          referencesLoader.loadDeliveryPickup(),
        ]);
      } catch (error) {
        errorHandler(STRINGS.PARCEL_FETCHING_ERROR, error);
      } finally {
        overlay.hide();
      }
    };

    loadReferences();
  }, []);

  const onSubmit = useCallback(async formValues => {
    try {
      overlay.show();

      let basketItemId = searchParams.get('basketItemId');
      const updatedBasketItem = {
        basketItemId: basketItemId,
        shipment: formValues,
      };

      if (basketItemId) {
        await dispatch(
          BasketActions.updateBasketItem(updatedBasketItem)
        ).unwrap();
      } else {
        const { basketItemId: itemId } = await dispatch(
          BasketActions.createBasketItem(formValues)
        ).unwrap();
        basketItemId = itemId;
      }

      setSearchParams({ ...searchParams, basketItemId });

      nextStep();
    } catch (e) {
      snackbar.showError({
        message: STRINGS.FAILED_TO_SAVE_BASKET,
      });
    } finally {
      overlay.hide();
    }
  }, []);

  const handleBack = useCallback(() => {
    navigate('/');
  }, [navigate]);

  // PRICING LOGIC
  const collectionPostcode = get(
    values,
    SHIPMENT_FIELDS.COLLECTION_POSTCODE.KEY
  );
  const collectionCountryCode = get(
    values,
    SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY
  );
  const deliveryCountryCode = get(
    values,
    SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY
  );

  const debounceTime = 300;

  const deliveryCountryValue = useMemo(
    () =>
      AddressUtil.getCountryValue({
        countries,
        countryCode: deliveryCountryCode,
      }),
    [countries, deliveryCountryCode]
  );

  const collectionCountryValue = useMemo(
    () =>
      AddressUtil.getCountryValue({
        countries,
        countryCode: collectionCountryCode,
      }),
    [countries, collectionCountryCode]
  );

  const debouncedFetchNetworks = useCallback(
    debounce(query => referencesLoader.loadNetworks(query), debounceTime),
    []
  );

  useDeepCompareEffect(() => {
    const networksSchema = Validators.getNetworksSchema(
      deliveryCountryValue,
      collectionCountryValue
    );

    const { error } = networksSchema.validate(networksQuery);

    if (!error) {
      debouncedFetchNetworks(networksQuery);
    }
  }, [networksQuery]);
  const [isAddressSectionsInvalid, setIsAddressSectionsInvalid] =
    useState(false);
  const collectFrom = get(values, SHIPMENT_FIELDS.COLLECT_FROM.KEY);
  const shipmentDate = get(values, SHIPMENT_FIELDS.SHIPMENT_DATE.KEY);
  const deliverTo = get(values, SHIPMENT_FIELDS.DELIVER_TO.KEY);
  const isTotalQuantityOverLimit =
    get(values, SHIPMENT_FIELDS.NUMBER_OF_PARCELS.KEY) > totalQuantityLimit;
  const totalParcelsValue = get(values, SHIPMENT_FIELDS.PARCELS.KEY, []).reduce(
    (totalParcelsValue, parcel) =>
      totalParcelsValue +
      Number(parcel._value ?? 0) * Number(parcel._quantity ?? 0),
    0
  );
  const isTotalValueOverLimit = totalParcelsValue > maxTotalParcelsValue;
  const senderAddressDisabled =
    !get(values, SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY) &&
    !AddressUtil.isCountryIE(
      get(values, SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY)
    );
  const parcelSizeDisabled =
    senderAddressDisabled ||
    isAddressSectionsInvalid ||
    !get(values, SHIPMENT_FIELDS.COLLECTION_POSTCODE.KEY);

  const parcelCollectionAvailable =
    networks.some(({ networkKey }) => networkKey !== '2^91') &&
    deliverTo !== POINT_TYPE.SHOP;

  const parcelDestinationDisabled =
    !networks?.length ||
    parcelSizeDisabled ||
    parcelsInvalid ||
    isTotalQuantityOverLimit ||
    isTotalValueOverLimit;

  const parcelCollectionDisabled =
    parcelDestinationDisabled ||
    !deliverTo ||
    (!get(values, SHIPMENT_FIELDS.PICKUP_DETAILS.KEY) &&
      deliverTo !== POINT_TYPE.DOOR) ||
    isTotalQuantityOverLimit ||
    isTotalValueOverLimit;
  const networkDisabled =
    parcelDestinationDisabled ||
    !collectFrom ||
    (collectFrom === POINT_TYPE.DOOR && !shipmentDate) ||
    isTotalQuantityOverLimit ||
    isTotalValueOverLimit;
  const insuranceVisible = !!(
    selectedNetwork &&
    selectedNetwork.insurance &&
    !networkDisabled
  );
  const activeCollectionPickupPoint =
    references[REFERENCE_NAME.COLLECTION_PICKUP];
  const showActiveCollectionPickupPoint =
    get(values, SHIPMENT_FIELDS.COLLECT_FROM.KEY) === POINT_TYPE.SHOP &&
    activeCollectionPickupPoint;
  const activeDestinationPickupPoint =
    references[REFERENCE_NAME.DELIVERY_PICKUP];
  const showActiveDestinationPickupPoint =
    get(values, SHIPMENT_FIELDS.DELIVER_TO.KEY) === POINT_TYPE.SHOP &&
    activeDestinationPickupPoint;
  // review
  const context = useSelector(StepSelectors.getMaxWeightAndNumberOfParcels);
  const requiredKeys = useSelector(
    StepSelectors.getParcelDetailsRequiredFields
  );
  const requiredKeysMap = useSelector(
    StepSelectors.getParcelDetailsRequiredFieldsMap
  );
  const collectionDateAvailable = collectFrom === POINT_TYPE.DOOR;

  const price = useSelector(OrderSelectors.getTotalAmount);

  const getDisplayedExtraPrice = price =>
    price ? `+${Formatters.formatPrice(price)}` : STRINGS.FREE;

  const loadCollectionDates = useCallback(
    async ({ form, collectionCountryCode, collectionPostcode }) => {
      if (collectionDates.length) {
        return;
      }
      try {
        const data = await dispatch(
          AppActions.fetchCollectionDates({
            countryCode: collectionCountryCode,
            postcode: collectionPostcode,
          })
        ).unwrap();

        const cutOffDates = ShipmentHelper.getCutOffDates(data);
        const dates = cutOffDates.map(({ date, collectionCutOff }) =>
          ShipmentHelper.formatShipmentDate({
            date,
            collectionCutOff: collectionCutOff || defaultCutOff,
          })
        );

        if (!shipmentDate || !dates.includes(shipmentDate)) {
          form.change(SHIPMENT_FIELDS.SHIPMENT_DATE.KEY, dates[0]);
        }
        setCollectionDates(dates);
      } catch (error) {
        const defaultDate = getDefaultShipmentDate();
        form.change(SHIPMENT_FIELDS.SHIPMENT_DATE.KEY, defaultDate);
        setCollectionDates([defaultDate]);
      }
    },
    [dispatch, shipmentDate, collectionDates]
  );

  const handleParcelValueChange = useCallback(() => {
    const recommendedCoverage = FormUtil.getTotalCoverage(
      get(values, SHIPMENT_FIELDS.PARCELS.KEY),
      insuranceConfig
    );

    setRecommended({ [recommendedCoverage]: true });
  }, [insuranceConfig, get(values, SHIPMENT_FIELDS.PARCELS.KEY)]);

  useDeepCompareEffect(() => {
    handleParcelValueChange();
  }, [values]);

  const handleTotalValueChange = (form, values) => {
    const insuranceValue = get(values, SHIPMENT_FIELDS.LIABILITY_VALUE.KEY);
    const recommendedCoverage = FormUtil.getTotalCoverage(
      get(values, SHIPMENT_FIELDS.PARCELS.KEY),
      insuranceConfig
    );
    if (
      insuranceValue === freeInsurance?.insuranceValue ||
      insuranceValue === recommendedCoverage
    ) {
      return;
    }
    form.change(
      SHIPMENT_FIELDS.LIABILITY_VALUE.KEY,
      freeInsurance.insuranceValue
    );
  };

  const onSelectFromAddressBook = async (form, selectedAddress, field) => {
    try {
      const {
        addressBookId,
        countryCode,
        addressLine1,
        addressLine2,
        addressLine3: town,
        addressLine4: county,
        postcode,
        contactName,
        organisation,
        phoneNumber,
        email,
      } = selectedAddress;

      const addressBookFormValues = {
        address: {
          [FIELDS.ADDRESS_KEY.KEY]: null,
          countryCode,
          street: addressLine1,
          locality: addressLine2,
          town,
          county,
          postcode,
          organisation: organisation || contactName,
          _coordinates: null,
        },
        contactDetails: {
          _addressBookId: addressBookId,
          contactName,
          telephone: phoneNumber,
          email,
        },
      };

      if (DELIVERY_DETAILS_KEY === field) {
        addressBookFormValues.notificationDetails = {
          mobile: phoneNumber,
          email,
        };

        setReferences(REFERENCE_NAME.DELIVERY_ADDRESS_BOOK, selectedAddress);
      } else {
        setReferences(REFERENCE_NAME.COLLECTION_ADDRESS_BOOK, selectedAddress);
      }

      form.change(field, addressBookFormValues);
    } catch (err) {
      snackbar.showError({
        message: 'Can not retrieve address details',
      });
    } finally {
      overlay.hide();
    }
  };

  const showNotificationError = (isError, errorMessage) => {
    if (isError) {
      snackbar.showError({
        message: errorMessage,
        persist: true,
        actionButtonText: STRINGS.DISMISS,
      });
    }
  };

  const handleRemoveSaveNextTimeFlags = (form, values) => {
    if (
      !isNil(get(values, SHIPMENT_FIELDS.SAVE_FOR_NEXT_TIME.KEY)) ||
      !isNil(get(values, SHIPMENT_FIELDS.SAVE_FOR_NEXT_TIME_RECEIVER.KEY))
    ) {
      form.change(SHIPMENT_FIELDS.SAVE_FOR_NEXT_TIME.KEY, undefined);
      form.change(SHIPMENT_FIELDS.SAVE_FOR_NEXT_TIME_RECEIVER.KEY, undefined);
    }
  };
  return (
    <Trackable loadId={PARCEL_DETAILS.LOAD}>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <OrderForm
          onSubmit={onSubmit}
          context={{
            deliveryCountryValue,
            collectionCountryValue,
            ...context,
          }}
          requiredKeys={requiredKeys}
        >
          {({ handleSubmit, values, invalid, form, errors }) => {
            setIsAddressSectionsInvalid(
              get(errors, SHIPMENT_FIELDS.DESTINATION_ADDRESS.KEY) ||
                get(errors, SHIPMENT_FIELDS.COLLECTION_ADDRESS.KEY)
            );

            handleRemoveSaveNextTimeFlags(form, values);

            return (
              <>
                <Grid item xs={12} md={8}>
                  <form id='parcelDetailsForm' onSubmit={handleSubmit}>
                    <FormSection
                      title={STRINGS.WHERE_YOUR_PARCEL_GOES}
                      section={SECTION.DELIVERY_ADDRESS}
                      related={[
                        SECTION.DELIVER_TO,
                        SECTION.COLLECT_FROM,
                        SECTION.SHIPMENT_DATE,
                        SECTION.NETWORK,
                        SECTION.INSURANCE,
                      ]}
                    >
                      <AddressDetailsForm
                        values={values}
                        config={{
                          fields: {
                            addressId: {
                              name: SHIPMENT_FIELDS.DESTINATION_ADDRESS_KEY.KEY,
                              props: { sx: { display: 'none' } },
                            },
                            coordinates: {
                              name: SHIPMENT_FIELDS
                                .DESTINATION_ADDRESS_COORDINATES.KEY,
                              props: { sx: { display: 'none' } },
                            },
                            country: {
                              name: SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY,
                              label: SHIPMENT_FIELDS.DESTINATION_COUNTRY.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY
                                ],
                            },
                            postcode: {
                              name: SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY,
                              label: SHIPMENT_FIELDS.DESTINATION_POSTCODE.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY
                                ],
                              disabled: !deliveryCountryCode,
                            },
                            organisation: {
                              name: SHIPMENT_FIELDS.DESTINATION_ORGANISATION
                                .KEY,
                              label:
                                SHIPMENT_FIELDS.DESTINATION_ORGANISATION.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.DESTINATION_ORGANISATION.KEY
                                ],
                              disabled: !deliveryCountryCode,
                            },
                            street: {
                              name: SHIPMENT_FIELDS.DESTINATION_STREET.KEY,
                              label: SHIPMENT_FIELDS.DESTINATION_STREET.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.DESTINATION_STREET.KEY
                                ],
                              disabled: !deliveryCountryCode,
                            },
                            locality: {
                              name: SHIPMENT_FIELDS.DESTINATION_LOCALITY.KEY,
                              label: SHIPMENT_FIELDS.DESTINATION_LOCALITY.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.DESTINATION_LOCALITY.KEY
                                ],
                              disabled: !deliveryCountryCode,
                            },
                            town: {
                              name: SHIPMENT_FIELDS.DESTINATION_CITY.KEY,
                              label: SHIPMENT_FIELDS.DESTINATION_CITY.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.DESTINATION_CITY.KEY
                                ],
                              disabled: !deliveryCountryCode,
                            },
                            county: {
                              name: SHIPMENT_FIELDS.DESTINATION_COUNTY.KEY,
                              label: SHIPMENT_FIELDS.DESTINATION_COUNTY.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.DESTINATION_COUNTY.KEY
                                ],
                              disabled: !deliveryCountryCode,
                            },
                          },
                          hasActionButtons: true,
                          idPrefix: 'delivery',
                          addressBookConfig: {
                            addressBookType:
                              SHIPMENT_FIELDS.DELIVERY_ADDRESS_BOOK_ID.KEY,
                          },
                        }}
                      />
                      <AddressBookModal
                        sx={{ mt: 2 }}
                        onSelect={selectedAddresses =>
                          onSelectFromAddressBook(
                            form,
                            selectedAddresses,
                            DELIVERY_DETAILS_KEY
                          )
                        }
                      />
                    </FormSection>
                    <FormSection
                      title={STRINGS.WHERE_YOUR_PARCEL_FROM}
                      section={SECTION.COLLECTION_ADDRESS}
                      disabled={senderAddressDisabled}
                      related={[
                        SECTION.DELIVER_TO,
                        SECTION.COLLECT_FROM,
                        SECTION.SHIPMENT_DATE,
                        SECTION.NETWORK,
                        SECTION.INSURANCE,
                      ]}
                    >
                      <AddressDetailsForm
                        values={{
                          ...values,
                          // Only GB should be available in Send From countries
                          [SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY]: STRINGS.GB,
                        }}
                        config={{
                          availableCountryCodes: [STRINGS.GB],
                          fields: {
                            addressId: {
                              name: SHIPMENT_FIELDS.COLLECTION_ADDRESS_KEY.KEY,
                              props: { sx: { display: 'none' } },
                            },
                            coordinates: {
                              name: SHIPMENT_FIELDS
                                .COLLECTION_ADDRESS_COORDINATES.KEY,
                              props: { sx: { display: 'none' } },
                            },
                            country: {
                              name: SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY,
                              label: SHIPMENT_FIELDS.COLLECTION_COUNTRY.LABEL,
                              // Disable change to make only GB set
                              disabled: !!get(
                                values,
                                SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY
                              ),
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.COLLECTION_COUNTRY.KEY
                                ],
                            },
                            postcode: {
                              name: SHIPMENT_FIELDS.COLLECTION_POSTCODE.KEY,
                              label: SHIPMENT_FIELDS.COLLECTION_POSTCODE.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.COLLECTION_POSTCODE.KEY
                                ],
                            },
                            organisation: {
                              name: SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY,
                              label:
                                SHIPMENT_FIELDS.COLLECTION_ORGANISATION.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY
                                ],
                            },
                            street: {
                              name: SHIPMENT_FIELDS.COLLECTION_STREET.KEY,
                              label: SHIPMENT_FIELDS.COLLECTION_STREET.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.COLLECTION_STREET.KEY
                                ],
                            },
                            locality: {
                              name: SHIPMENT_FIELDS.COLLECTION_LOCALITY.KEY,
                              label: SHIPMENT_FIELDS.COLLECTION_LOCALITY.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.COLLECTION_LOCALITY.KEY
                                ],
                            },
                            town: {
                              name: SHIPMENT_FIELDS.COLLECTION_CITY.KEY,
                              label: SHIPMENT_FIELDS.COLLECTION_CITY.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.COLLECTION_CITY.KEY
                                ],
                            },
                            county: {
                              name: SHIPMENT_FIELDS.COLLECTION_COUNTY.KEY,
                              label: SHIPMENT_FIELDS.COLLECTION_COUNTY.LABEL,
                              required:
                                requiredKeysMap[
                                  SHIPMENT_FIELDS.COLLECTION_COUNTY.KEY
                                ],
                            },
                          },
                          hasActionButtons: true,
                          idPrefix: 'collect',
                          addressBookConfig: {
                            addressBookType:
                              SHIPMENT_FIELDS.COLLECTION_ADDRESS_BOOK_ID.KEY,
                            customization: {
                              // Disable all non 'GB' addresses in address book
                              rowStyle: item =>
                                !AddressUtil.isCountryGB(item.countryCode)
                                  ? { opacity: 0.5, pointerEvents: 'none' }
                                  : undefined,
                            },
                          },
                        }}
                        disabled={senderAddressDisabled}
                      />
                      <AddressBookModal
                        sx={{ mt: 2 }}
                        onSelect={selectedAddresses =>
                          onSelectFromAddressBook(
                            form,
                            selectedAddresses,
                            COLLECTION_DETAILS_KEY
                          )
                        }
                        config={{
                          customization: {
                            // Disable all non 'GB' addresses in address book
                            rowStyle: item =>
                              item.countryCode !== 'GB'
                                ? { opacity: 0.5, pointerEvents: 'none' }
                                : undefined,
                          },
                        }}
                      />
                    </FormSection>
                    {/* parcels section */}
                    {get(values, SHIPMENT_FIELDS.PARCELS.KEY, []).map(
                      (parcel, index) => (
                        <FormSection
                          key={index}
                          section={SECTION.PARCEL}
                          title={STRINGS.HOW_BIG_IS_YOUR_PARCEL}
                          disabled={parcelSizeDisabled}
                          related={[
                            SECTION.DELIVER_TO,
                            SECTION.COLLECT_FROM,
                            SECTION.SHIPMENT_DATE,
                            SECTION.NETWORK,
                            SECTION.INSURANCE,
                          ]}
                        >
                          <Grid
                            container
                            sx={{
                              my: { xs: 1, md: 2 },
                              mt: { md: 3 },
                              alignItems: 'flex-start',
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{
                                paddingTop: { xs: '18px', md: 0 },
                              }}
                            >
                              <Typography variant='subtitle2'>
                                {STRINGS.WEIGHT}
                              </Typography>
                              <Typography variant='caption'>
                                {STRINGS.MAX_PARCEL_WEIGHT_$(
                                  context.maxWeight || 30
                                )}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              md={4}
                              sx={{
                                pr: 1,
                                mt: { xs: 2, md: 0 },
                              }}
                            >
                              <TextFieldRFF
                                name={SHIPMENT_FIELDS.PARCEL_WEIGHT.KEY(index)}
                                label={SHIPMENT_FIELDS.PARCEL_WEIGHT.LABEL}
                                disabled={parcelSizeDisabled}
                                required={
                                  requiredKeysMap[
                                    SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY
                                  ]
                                }
                                fieldProps={{
                                  parse: value =>
                                    Normalizers.formatAndConstrainDecimal({
                                      ...weightFieldConfig,
                                      value,
                                    }),
                                  format: StringUtil.formatDecimalString,
                                  formatOnBlur: true,
                                }}
                              />
                              <OnChange
                                name={SHIPMENT_FIELDS.PARCEL_WEIGHT.KEY(index)}
                              >
                                {() => {
                                  form.change(
                                    SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY,
                                    NetworkUtil.getParcelsTotalWeight(values)
                                  );
                                }}
                              </OnChange>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            sx={{
                              my: 2,
                              mt: { xs: 1, md: 2 },
                              alignItems: 'flex-start',
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{
                                paddingTop: { xs: '18px', md: 0 },
                              }}
                            >
                              <Typography variant='subtitle2'>
                                {STRINGS.HEIGHT}
                              </Typography>
                              <Typography variant='caption'>
                                {STRINGS.MAX_PARCEL_HEIGHT_$(100)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{
                                pr: 1,
                                mt: { xs: 2, md: 0 },
                              }}
                            >
                              <TextFieldRFF
                                name={SHIPMENT_FIELDS.PARCEL_HEIGHT.KEY(index)}
                                label={SHIPMENT_FIELDS.PARCEL_HEIGHT.LABEL}
                                disabled={parcelSizeDisabled}
                                required={
                                  requiredKeysMap[
                                    SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY
                                  ]
                                }
                                fieldProps={{
                                  parse: value =>
                                    StringUtil.restrictToDigitsAndLimit(value),
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            sx={{
                              my: 2,
                              mt: { xs: 1, md: 2 },
                              alignItems: 'flex-start',
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{
                                paddingTop: { xs: '18px', md: 0 },
                              }}
                            >
                              <Typography variant='subtitle2'>
                                {STRINGS.WIDTH}
                              </Typography>
                              <Typography variant='caption'>
                                {STRINGS.MAX_PARCEL_WIDTH_$(100)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{
                                pr: 1,
                                mt: { xs: 2, md: 0 },
                              }}
                            >
                              <TextFieldRFF
                                name={SHIPMENT_FIELDS.PARCEL_WIDTH.KEY(index)}
                                label={SHIPMENT_FIELDS.PARCEL_WIDTH.LABEL}
                                disabled={parcelSizeDisabled}
                                required={
                                  requiredKeysMap[
                                    SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY
                                  ]
                                }
                                fieldProps={{
                                  parse: value =>
                                    StringUtil.restrictToDigitsAndLimit(value),
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            sx={{
                              my: 2,
                              mt: { xs: 1, md: 2 },
                              alignItems: 'flex-start',
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{
                                paddingTop: { xs: '18px', md: 0 },
                              }}
                            >
                              <Typography variant='subtitle2'>
                                {STRINGS.LENGTH}
                              </Typography>
                              <Typography variant='caption'>
                                {STRINGS.MAX_PARCEL_LENGTH_$(100)}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{
                                pr: 1,
                                mt: { xs: 2, md: 0 },
                              }}
                            >
                              <TextFieldRFF
                                name={SHIPMENT_FIELDS.PARCEL_LENGTH.KEY(index)}
                                label={SHIPMENT_FIELDS.PARCEL_LENGTH.LABEL}
                                disabled={parcelSizeDisabled}
                                required={
                                  requiredKeysMap[
                                    SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY
                                  ]
                                }
                                fieldProps={{
                                  parse: value =>
                                    StringUtil.restrictToDigitsAndLimit(value),
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            sx={{
                              my: 2,
                              mt: { xs: 1, md: 2 },
                              alignItems: 'flex-start',
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{
                                paddingTop: { xs: '18px', md: 0 },
                              }}
                            >
                              <Typography variant='subtitle2'>
                                {STRINGS.QUANTITY}
                              </Typography>
                              <Typography variant='caption'>
                                {STRINGS.QUANTITY_DESCRIPTION}
                              </Typography>
                              <br />
                              <Typography variant='caption'>
                                {STRINGS.MAX_QUANTITY_$(
                                  context.maxParcelNumber || 99
                                )}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{
                                pr: 1,
                                mt: { xs: 2, md: 0 },
                              }}
                            >
                              <TextFieldRFF
                                name={SHIPMENT_FIELDS.PARCEL_QUANTITY.KEY(
                                  index
                                )}
                                label={SHIPMENT_FIELDS.PARCEL_QUANTITY.LABEL}
                                disabled={parcelSizeDisabled}
                                required={
                                  requiredKeysMap[
                                    SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY
                                  ]
                                }
                                fieldProps={{
                                  parse: value =>
                                    StringUtil.restrictToDigitsAndLimit(
                                      value,
                                      quantitySymbolsLengthLimit
                                    ),
                                }}
                                InputProps={{
                                  sx: theme =>
                                    isTotalQuantityOverLimit && {
                                      '& fieldset': {
                                        borderColor: theme.palette.error.main,
                                      },
                                      '&:hover fieldset': {
                                        borderColor: `${theme.palette.error.main}!important`,
                                      },
                                    },
                                }}
                                InputLabelProps={{
                                  sx: theme =>
                                    isTotalQuantityOverLimit && {
                                      color: theme.palette.error.main,
                                    },
                                }}
                              />
                              <OnChange
                                name={SHIPMENT_FIELDS.PARCEL_QUANTITY.KEY(
                                  index
                                )}
                              >
                                {() => {
                                  form.batch(() => {
                                    form.change(
                                      SHIPMENT_FIELDS.NUMBER_OF_PARCELS.KEY,
                                      NetworkUtil.getParcelTotalQuantity(
                                        get(
                                          values,
                                          SHIPMENT_FIELDS.PARCELS.KEY,
                                          []
                                        )
                                      )
                                    );
                                    form.change(
                                      SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY,
                                      NetworkUtil.getParcelsTotalWeight(values)
                                    );
                                  });
                                }}
                              </OnChange>
                              <OnChange
                                name={SHIPMENT_FIELDS.PARCEL_QUANTITY.KEY(
                                  index
                                )}
                              >
                                {() => {
                                  handleTotalValueChange(form, values);
                                }}
                              </OnChange>
                              <OnBlur
                                name={SHIPMENT_FIELDS.PARCEL_QUANTITY.KEY(
                                  index
                                )}
                              >
                                {() => {
                                  showNotificationError(
                                    isTotalQuantityOverLimit,
                                    TOTAL_QUANTITY_ERROR(totalQuantityLimit)
                                  );
                                  showNotificationError(
                                    isTotalValueOverLimit,
                                    TOTAL_VALUE_ERROR(maxTotalParcelsValue)
                                  );
                                }}
                              </OnBlur>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            sx={{
                              my: 2,
                              mt: { xs: 1, md: 2 },
                              alignItems: 'flex-start',
                            }}
                          >
                            <Grid
                              item
                              xs={8}
                              sx={{
                                paddingTop: { xs: '18px', md: 0 },
                              }}
                            >
                              <Typography variant='subtitle2'>
                                {STRINGS.VALUE}
                              </Typography>
                              <Typography variant='caption'>
                                {STRINGS.VALUE_DESCRIPTION}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{
                                pr: 1,
                                mt: { xs: 2, md: 0 },
                              }}
                            >
                              <TextFieldRFF
                                name={SHIPMENT_FIELDS.PARCEL_VALUE.KEY(index)}
                                label={SHIPMENT_FIELDS.PARCEL_VALUE.LABEL}
                                disabled={parcelSizeDisabled}
                                required={Validators.isFieldRequired(
                                  parcelSchema(context),
                                  '_value'
                                )}
                                fieldProps={{
                                  parse: value =>
                                    Normalizers.formatAndConstrainDecimal({
                                      value,
                                      maxIntegerLength: valueSymbolsLengthLimit,
                                      maxDecimalLength:
                                        quantitySymbolsLengthLimit,
                                      minValue: minParcelValue,
                                    }),
                                  format: StringUtil.formatDecimalString,
                                  formatOnBlur: true,
                                }}
                                InputProps={{
                                  sx: theme =>
                                    isTotalValueOverLimit && {
                                      '& fieldset': {
                                        borderColor: theme.palette.error.main,
                                      },
                                      '&:hover fieldset': {
                                        borderColor: `${theme.palette.error.main}!important`,
                                      },
                                    },
                                }}
                                InputLabelProps={{
                                  sx: theme =>
                                    isTotalValueOverLimit && {
                                      color: theme.palette.error.main,
                                    },
                                }}
                              />
                              <OnBlur
                                name={SHIPMENT_FIELDS.PARCEL_VALUE.KEY(index)}
                              >
                                {() => {
                                  showNotificationError(
                                    isTotalValueOverLimit,
                                    TOTAL_VALUE_ERROR(maxTotalParcelsValue)
                                  );
                                }}
                              </OnBlur>
                            </Grid>
                            <OnChange
                              name={SHIPMENT_FIELDS.PARCEL_VALUE.KEY(index)}
                            >
                              {() => {
                                handleTotalValueChange(form, values);
                              }}
                            </OnChange>
                          </Grid>
                          <Grid container sx={{ my: 1 }}>
                            <Grid item>
                              <Button
                                sx={{ px: 0 }}
                                variant='text'
                                onClick={() => setMaxDimsModalOpen(true)}
                                disabled={parcelSizeDisabled}
                              >
                                {STRINGS.VIEW_MAX_PARCEL_SIZES}
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid sx={{ my: 1.5 }}>
                            <Grid item>
                              <Typography variant='body1'>
                                {STRINGS.PARCEL_DESCRIPTION}
                              </Typography>
                            </Grid>
                          </Grid>
                          <TextFieldRFF
                            sx={{ mb: 2 }}
                            name={SHIPMENT_FIELDS.PARCEL_DESCRIPTION.KEY(index)}
                            size='small'
                            label={SHIPMENT_FIELDS.PARCEL_DESCRIPTION.LABEL}
                            fullWidth
                            disabled={parcelSizeDisabled}
                            fieldProps={{
                              formatOnBlur: true,
                              format: Formatters.trim,
                            }}
                          />
                          <Grid
                            container
                            sx={{
                              mt: 1,
                              flexDirection: {
                                xs: 'column-reverse',
                                md: 'row',
                              },
                              justifyContent: {
                                xs: 'space-between',
                                md: 'flex-end',
                              },
                            }}
                          >
                            {get(values, SHIPMENT_FIELDS.PARCELS.KEY, [])
                              .length > 1 && (
                              <Button
                                data-testid={`outboundConsignment.parcels[${index}]._delete_button`}
                                variant={'outlined'}
                                onClick={() => {
                                  const newParcels = cloneDeep(
                                    get(values, SHIPMENT_FIELDS.PARCELS.KEY, [])
                                  );
                                  const parcelToDelete = newParcels.find(
                                    item => item.parcelId === parcel.parcelId
                                  );
                                  const deletedParcelIndex =
                                    newParcels.indexOf(parcelToDelete);

                                  // Delete parcel by index
                                  newParcels.splice(deletedParcelIndex, 1);

                                  form.batch(() => {
                                    form.change(
                                      SHIPMENT_FIELDS.PARCELS.KEY,
                                      newParcels
                                    );
                                    form.change(
                                      SHIPMENT_FIELDS.NUMBER_OF_PARCELS.KEY,
                                      NetworkUtil.getParcelTotalQuantity(
                                        newParcels
                                      )
                                    );
                                    form.change(
                                      SHIPMENT_FIELDS.TOTAL_WEIGHT.KEY,
                                      NetworkUtil.getParcelsTotalWeight(
                                        values,
                                        newParcels
                                      )
                                    );
                                  });

                                  // Filter out from SECTION_FIELDS_MAP items that contain
                                  // deletedParcelIndex in the name
                                  SECTION_FIELDS_MAP.parcel = filter(
                                    SECTION_FIELDS_MAP.parcel,
                                    item =>
                                      !item.name.includes(
                                        `parcels[${deletedParcelIndex}]`
                                      )
                                  );
                                }}
                                disabled={parcelSizeDisabled}
                                startIcon={<Delete />}
                                sx={{ marginTop: { xs: 1, md: 0 } }}
                              >
                                {STRINGS.DELETE_PARCEL}
                              </Button>
                            )}
                            {index ===
                              get(values, SHIPMENT_FIELDS.PARCELS.KEY, [])
                                .length -
                                1 && (
                              <Button
                                variant='outlined'
                                onClick={() => {
                                  const newParcels = cloneDeep(
                                    get(values, SHIPMENT_FIELDS.PARCELS.KEY, [])
                                  );
                                  newParcels.push({
                                    parcelId: uuidv4(),
                                  });
                                  form.change(
                                    SHIPMENT_FIELDS.PARCELS.KEY,
                                    newParcels
                                  );
                                  SECTION_FIELDS_MAP.parcel.push(
                                    ...[
                                      {
                                        name: SHIPMENT_FIELDS.PARCEL_WEIGHT.KEY(
                                          get(
                                            values,
                                            SHIPMENT_FIELDS.PARCELS.KEY,
                                            []
                                          ).length
                                        ),
                                      },
                                      {
                                        name: SHIPMENT_FIELDS.PARCEL_QUANTITY.KEY(
                                          get(
                                            values,
                                            SHIPMENT_FIELDS.PARCELS.KEY,
                                            []
                                          ).length
                                        ),
                                      },
                                    ]
                                  );
                                }}
                                disabled={parcelSizeDisabled}
                                startIcon={<Add />}
                                sx={{ ml: { xs: 0, md: 1 } }}
                              >
                                {STRINGS.ADD_ANOTHER_PARCEL}
                              </Button>
                            )}
                          </Grid>
                        </FormSection>
                      )
                    )}
                    <FormSection
                      title={STRINGS.WHERE_DELIVER_PARCEL_TO}
                      section={SECTION.DELIVER_TO}
                      disabled={parcelDestinationDisabled}
                      related={[
                        SECTION.COLLECT_FROM,
                        SECTION.SHIPMENT_DATE,
                        SECTION.NETWORK,
                        SECTION.INSURANCE,
                      ]}
                    >
                      <Field name={SHIPMENT_FIELDS.DELIVER_TO.KEY}>
                        {({ input }) => (
                          <Grid
                            container
                            spacing={2}
                            sx={{ mt: 2, alignItems: 'stretch' }}
                          >
                            <Grid container item md={6} xs={12}>
                              <ActionButton
                                selected={input.value === POINT_TYPE.SHOP}
                                highlightChip={STRINGS.MOST_POPULAR}
                                title={STRINGS.DIRECT_TO_PICKUP}
                                bodyText={STRINGS.DIRECT_TO_PICKUP_DESCRIPTION}
                                price='Free'
                                cardOnclick={() => {
                                  input.onChange(POINT_TYPE.SHOP);
                                }}
                                disabled={
                                  parcelDestinationDisabled ||
                                  !shipToShopAvailable
                                }
                              />
                              <PickupModal
                                label={STRINGS.SELECT_DPD_PICKUP_POINT}
                                id='view-collection-pickups'
                                variant='text'
                                showSelectButton={true}
                                disabled={
                                  input.value !== POINT_TYPE.SHOP ||
                                  parcelDestinationDisabled ||
                                  !shipToShopAvailable
                                }
                                selectedPickupPoint={
                                  activeDestinationPickupPoint
                                }
                                onChange={newPickupPoint => {
                                  setReferences(
                                    REFERENCE_NAME.DELIVERY_PICKUP,
                                    newPickupPoint
                                  );
                                  form.change(
                                    SHIPMENT_FIELDS.PICKUP_DETAILS.KEY,
                                    PickupHelper.convertPickupToFormValues(
                                      newPickupPoint
                                    )
                                  );
                                }}
                                initialPostcodeValue={get(
                                  values,
                                  SHIPMENT_FIELDS.DESTINATION_POSTCODE.KEY
                                )}
                              />
                            </Grid>
                            <Grid container item md={6} xs={12}>
                              <ActionButton
                                selected={input.value === POINT_TYPE.DOOR}
                                title={STRINGS.DELIVER_TO_RECIPIENT}
                                bodyText={
                                  STRINGS.DELIVER_TO_RECIPIENT_DESCRIPTION
                                }
                                price={getDisplayedExtraPrice(
                                  price.deliveryExtraPrice
                                )}
                                cardOnclick={() => {
                                  input.onChange(POINT_TYPE.DOOR);
                                  form.change(
                                    SHIPMENT_FIELDS.PICKUP_DETAILS.KEY,
                                    undefined
                                  );
                                }}
                                disabled={parcelDestinationDisabled}
                              />
                            </Grid>
                            {showActiveDestinationPickupPoint && (
                              <Grid container item md={12} xs={12}>
                                <PickupPointWidget
                                  pickupPoint={activeDestinationPickupPoint}
                                  pickupPointLabel={STRINGS.CHOSEN_PICKUP_POINT}
                                  showAddressInLine
                                />
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </Field>
                      <OnChange name={SHIPMENT_FIELDS.DELIVER_TO.KEY}>
                        {value => {
                          const dropAtShop =
                            value === POINT_TYPE.SHOP &&
                            get(values, SHIPMENT_FIELDS.COLLECT_FROM.KEY) ===
                              POINT_TYPE.SHOP;
                          form.change(
                            SHIPMENT_FIELDS.DROP_AT_SHOP.KEY,
                            dropAtShop ? true : undefined
                          );
                        }}
                      </OnChange>
                    </FormSection>
                    <FormSection
                      title={STRINGS.WOULD_YOU_LIKE_COLLECTION}
                      section={SECTION.COLLECT_FROM}
                      disabled={parcelCollectionDisabled}
                      related={[SECTION.NETWORK, SECTION.INSURANCE]}
                    >
                      <Field name={SHIPMENT_FIELDS.COLLECT_FROM.KEY}>
                        {({ input }) => (
                          <Grid
                            container
                            spacing={2}
                            sx={{ mt: 2, alignItems: 'stretch' }}
                          >
                            <Grid container item md={6} xs={12}>
                              <ActionButton
                                selected={input.value === POINT_TYPE.SHOP}
                                highlightChip={STRINGS.MOST_POPULAR}
                                title={STRINGS.NO_DROP_OFF}
                                bodyText={STRINGS.NO_DROP_OFF_DESCRIPTION}
                                price='Free'
                                cardOnclick={() => {
                                  input.onChange(POINT_TYPE.SHOP);
                                }}
                                disabled={parcelCollectionDisabled}
                              />
                              <PickupModal
                                label={STRINGS.VIEW_NEARBY_PICKUPS}
                                id='view-collection-pickups'
                                variant='text'
                                showSelectButton={false}
                                disabled={
                                  parcelCollectionDisabled ||
                                  input.value !== POINT_TYPE.SHOP
                                }
                                selectedPickupPoint={
                                  activeCollectionPickupPoint
                                }
                                onChange={newPickupPoint => {
                                  setReferences(
                                    REFERENCE_NAME.COLLECTION_PICKUP,
                                    newPickupPoint
                                  );

                                  form.change(
                                    SHIPMENT_FIELDS.DROP_OFF_DETAILS.KEY,
                                    PickupHelper.convertPickupToFormValues(
                                      newPickupPoint
                                    )
                                  );
                                }}
                                initialPostcodeValue={get(
                                  values,
                                  SHIPMENT_FIELDS.COLLECTION_POSTCODE.KEY
                                )}
                                config={{
                                  shopFinderSearch: {
                                    kinds: [PICKUP_POINT_TYPE.SHOP],
                                    selectedAllItem: {
                                      key: PICKUP_POINT_TYPE.SHOP,
                                      value: PICKUP_POINT_TYPE_VALUE.SHOP,
                                    },
                                  },
                                }}
                              />
                            </Grid>
                            <Grid container item md={6} xs={12}>
                              <ActionButton
                                selected={input.value === POINT_TYPE.DOOR}
                                title={STRINGS.YES_COLLECT}
                                bodyText={STRINGS.YES_COLLECT_DESCRIPTION}
                                price={
                                  parcelCollectionAvailable
                                    ? `+${Formatters.formatPrice(
                                        price.collectionExtraPrice
                                      )}`
                                    : '-'
                                }
                                printerRequired
                                cardOnclick={() => {
                                  input.onChange(POINT_TYPE.DOOR);
                                }}
                                disabled={
                                  parcelCollectionDisabled ||
                                  !parcelCollectionAvailable
                                }
                              />
                            </Grid>
                            {showActiveCollectionPickupPoint && (
                              <Grid container item md={12} xs={12}>
                                <PickupPointWidget
                                  pickupPoint={activeCollectionPickupPoint}
                                  pickupPointLabel={STRINGS.CHOSEN_PICKUP_POINT}
                                  showAddressInLine
                                />
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </Field>
                      <OnChange name={SHIPMENT_FIELDS.COLLECT_FROM.KEY}>
                        {value => {
                          const isCollectFromShop = value === POINT_TYPE.SHOP;

                          form.change(
                            SHIPMENT_FIELDS.DROP_AT_SHOP.KEY,
                            isCollectFromShop ? true : undefined
                          );

                          setCollectionDates([]);
                        }}
                      </OnChange>
                    </FormSection>
                    {collectionDateAvailable && (
                      <FormSection
                        title={STRINGS.COLLECTION_DATE_HEADER}
                        section={SECTION.SHIPMENT_DATE}
                        disabled={parcelCollectionDisabled}
                      >
                        <Loader
                          form={form}
                          promiseFn={loadCollectionDates}
                          collectionCountryCode={collectionCountryCode}
                          collectionPostcode={collectionPostcode}
                        >
                          {() => (
                            <Box sx={{ mt: 2 }}>
                              <TextFieldRFF
                                name={SHIPMENT_FIELDS.SHIPMENT_DATE.KEY}
                                label={SHIPMENT_FIELDS.SHIPMENT_DATE.LABEL}
                                disabled={parcelCollectionDisabled}
                                select
                                size='small'
                                required={
                                  requiredKeysMap[
                                    SHIPMENT_FIELDS.SHIPMENT_DATE.KEY
                                  ]
                                }
                              >
                                {collectionDates.map((date, index) => (
                                  <MenuItem key={index} value={date}>
                                    {moment(date, DATE_TIME_FORMAT).format(
                                      'dddd Do MMMM YYYY'
                                    )}
                                  </MenuItem>
                                ))}
                              </TextFieldRFF>
                            </Box>
                          )}
                        </Loader>
                      </FormSection>
                    )}
                    <FormSection
                      title={
                        collectFrom === POINT_TYPE.SHOP &&
                        deliverTo === POINT_TYPE.SHOP
                          ? STRINGS.WHEN_ARRIVE
                          : STRINGS.WHEN_DELIVER
                      }
                      section={SECTION.NETWORK}
                      disabled={networkDisabled}
                    >
                      {networkDisabled ? (
                        <Box sx={{ mt: 2 }}>
                          <ActionButton
                            title={STRINGS.AVAILABLE_SERVICES}
                            bodyText={STRINGS.WE_ARE_CALCULATING_SERVICES}
                            price='--,--'
                            disabled
                          />
                        </Box>
                      ) : (
                        <Field name={SHIPMENT_FIELDS.NETWORK_CODE.KEY}>
                          {({ input }) => (
                            <Grid
                              container
                              spacing={2}
                              sx={{ my: 2, alignItems: 'stretch' }}
                            >
                              {filteredNetworks.map(network => {
                                const { extraPrice } =
                                  find(price.arrivalServicesExtraPrices, [
                                    'networkKey',
                                    network.networkKey,
                                  ]) || {};

                                return (
                                  <Grid
                                    container
                                    item
                                    md={12 / filteredNetworks.length}
                                    xs={12}
                                    key={network.networkKey}
                                  >
                                    <ActionButton
                                      selected={
                                        input.value === network.networkKey
                                      }
                                      // highlightChip={STRINGS.MOST_POPULAR}
                                      title={
                                        NETWORK_DETAILS[network.networkKey]
                                          ?.title || network.networkDesc
                                      }
                                      bodyText={NETWORK_DETAILS[
                                        network.networkKey
                                      ]?.desc(shipmentDate)}
                                      price={
                                        network.networkKey !==
                                          NETWORK_KEYS.NETWORK_KEY_SHIP_TO_SHOP &&
                                        getDisplayedExtraPrice(extraPrice)
                                      }
                                      cardOnclick={() => {
                                        const fieldsToUpdate =
                                          getFieldsToUpdate({
                                            network,
                                            values,
                                          });

                                        input.onChange(network.networkKey);
                                        form.batch(() => {
                                          Object.entries(
                                            fieldsToUpdate
                                          ).forEach(([key, value]) => {
                                            form.change(key, value);
                                          });
                                        });
                                      }}
                                      disabled={networkDisabled}
                                    />
                                  </Grid>
                                );
                              })}
                            </Grid>
                          )}
                        </Field>
                      )}
                    </FormSection>
                    {insuranceVisible && (
                      <FormSection
                        title={STRINGS.WOULD_YOU_LIKE_INSURANCE}
                        section={SECTION.INSURANCE}
                        disabled={
                          isTotalQuantityOverLimit || isTotalValueOverLimit
                        }
                      >
                        <Field name={SHIPMENT_FIELDS.LIABILITY_VALUE.KEY}>
                          {({ input }) => (
                            <Grid
                              container
                              spacing={2}
                              sx={{ mt: 2, alignItems: 'stretch' }}
                            >
                              <Grid
                                container
                                item
                                md={
                                  recommendedInsurance?.insuranceCost !== 0
                                    ? '6'
                                    : '12'
                                }
                                xs={12}
                                key={`${freeInsurance.insuranceValue}-${freeInsurance.insuranceCost}`}
                              >
                                <ActionButton
                                  selected={
                                    String(input.value) ===
                                    String(freeInsurance.insuranceValue)
                                  }
                                  title={INSURANCE_SECTION.TITLE(
                                    freeInsurance.insuranceValue
                                  )}
                                  highlightChip={
                                    recommended[freeInsurance.insuranceValue] &&
                                    STRINGS.RECOMMENDED
                                  }
                                  bodyText={INSURANCE_SECTION.DESCRIPTION(
                                    freeInsurance.insuranceValue,
                                    freeInsurance.insuranceCost
                                  )}
                                  price={INSURANCE_SECTION.PRICE(
                                    freeInsurance.insuranceCost
                                  )}
                                  cardOnclick={() =>
                                    input.onChange(freeInsurance.insuranceValue)
                                  }
                                />
                              </Grid>
                              {recommendedInsurance &&
                                recommendedInsurance.insuranceCost !== 0 && (
                                  <Grid
                                    container
                                    item
                                    md={6}
                                    xs={12}
                                    key={`${recommendedInsurance.insuranceValue}-${recommendedInsurance.insuranceCost}`}
                                  >
                                    <ActionButton
                                      selected={
                                        String(input.value) ===
                                        String(
                                          recommendedInsurance.insuranceValue
                                        )
                                      }
                                      title={INSURANCE_SECTION.TITLE(
                                        recommendedInsurance.insuranceValue
                                      )}
                                      highlightChip={
                                        recommended[
                                          recommendedInsurance.insuranceValue
                                        ] && STRINGS.RECOMMENDED
                                      }
                                      bodyText={INSURANCE_SECTION.DESCRIPTION(
                                        recommendedInsurance.insuranceValue,
                                        recommendedInsurance.insuranceCost
                                      )}
                                      price={INSURANCE_SECTION.PRICE(
                                        recommendedInsurance.insuranceCost
                                      )}
                                      cardOnclick={() =>
                                        input.onChange(
                                          recommendedInsurance.insuranceValue
                                        )
                                      }
                                    />
                                  </Grid>
                                )}
                            </Grid>
                          )}
                        </Field>
                      </FormSection>
                    )}
                  </form>
                  <Debugger>
                    <pre>{JSON.stringify(errors, null, 2)}</pre>
                  </Debugger>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box sx={{ position: 'sticky', top: theme.spacing(2) }}>
                    <SummaryDetails
                      price={price}
                      basketItemsWithPrice={[
                        {
                          shipment: values,
                          price,
                        },
                      ]}
                      displayItemsPriceDetails={!parcelSizeDisabled}
                      displayInsurance={insuranceVisible}
                    />
                    <Box
                      sx={{
                        gap: 1,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {!isSmallScreen && (
                        <Button
                          actionid={PARCEL_DETAILS.CLICK_BACK}
                          variant='outlined'
                          onClick={handleBack}
                          sx={{ flexGrow: 1 }}
                        >
                          {STRINGS.BACK}
                        </Button>
                      )}
                      <Button
                        variant='contained'
                        form='parcelDetailsForm'
                        type='submit'
                        disabled={invalid}
                        sx={{ flexGrow: 1, marginX: { xs: 3, md: 0 } }}
                      >
                        {STRINGS.NEXT_STEP}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </>
            );
          }}
        </OrderForm>
      </Grid>
      <MaxParcelDimsModal
        open={maxDimsModalOpen}
        onClose={() => setMaxDimsModalOpen(false)}
      />
    </Trackable>
  );
};

export default ParcelDetails;
