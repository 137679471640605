import { find, get } from 'lodash';
import { Box, Divider, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import {
  DIRECT_TO_PICKUP,
  DROP_OFF_TO_PICKUP,
  FREE,
  SUMMARY_DETAILS,
} from '../../../constants/strings';
import { NETWORK_DETAILS } from '../../../constants/shipment';
import { FORM, POINT_TYPE } from '../../../constants';
import { getReceiverContactName } from '../../../helpers/shipment';
import { Formatters } from '../../../utils';
import ParcelsPriceDetails from './ParcelsPriceDetails';

const CheckoutListRow = ({ label, price }) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
    <Typography variant='body2'>{label}</Typography>
    <Typography variant='body2'>{price}</Typography>
  </Box>
);

const CollectDeliveryDetails = ({ shipment, price }) => {
  const deliverTo = get(shipment, FORM.SHIPMENT_FIELDS.DELIVER_TO.KEY);
  const collectFrom = get(shipment, FORM.SHIPMENT_FIELDS.COLLECT_FROM.KEY);
  const networkCode = get(shipment, FORM.SHIPMENT_FIELDS.NETWORK_CODE.KEY);
  const networkDesc = get(shipment, FORM.SHIPMENT_FIELDS.NETWORK_DESC.KEY);

  const selectedArrivalServiceExtraPrice =
    find(price.arrivalServicesExtraPrices, ['networkKey', networkCode]) || {};
  const getDisplayedExtraPrice = price =>
    price ? Formatters.formatPrice(price) : FREE;

  return (
    <>
      {deliverTo === POINT_TYPE.DOOR && (
        <CheckoutListRow
          label={SUMMARY_DETAILS.DELIVER_RECIPIENT}
          price={getDisplayedExtraPrice(price?.deliveryExtraPrice)}
        />
      )}
      {deliverTo === POINT_TYPE.SHOP && (
        <CheckoutListRow label={DIRECT_TO_PICKUP} price={FREE} />
      )}
      {collectFrom === POINT_TYPE.DOOR && (
        <CheckoutListRow
          label={SUMMARY_DETAILS.COLLECT_PARCEL}
          price={getDisplayedExtraPrice(price?.collectionExtraPrice)}
        />
      )}
      {collectFrom === POINT_TYPE.SHOP && (
        <CheckoutListRow label={DROP_OFF_TO_PICKUP} price={FREE} />
      )}
      {networkCode && (
        <CheckoutListRow
          label={get(NETWORK_DETAILS, `[${networkCode}].title`, networkDesc)}
          price={getDisplayedExtraPrice(
            selectedArrivalServiceExtraPrice.extraPrice
          )}
        />
      )}
    </>
  );
};

const CheckoutListItem = ({
  basketItem,
  isLastItem,
  showMoreDetails,
  displayInsurance,
}) => {
  const { liabilityAmount, basePrise, totalIncVatAmount } =
    basketItem?.price || {};
  const receiverContactName = getReceiverContactName(basketItem?.shipment);
  const { parcels, _networkDesc } =
    basketItem?.shipment?.outboundConsignment || {};

  return (
    <>
      <Box sx={{ py: 1 }}>
        <ParcelsPriceDetails
          receiverContactName={receiverContactName}
          parcels={parcels}
          networkDesc={_networkDesc}
          showMoreDetails={showMoreDetails}
          price={Formatters.formatPrice(basePrise || totalIncVatAmount) || FREE}
        />

        {showMoreDetails && (
          <CollectDeliveryDetails
            shipment={basketItem?.shipment}
            price={basketItem.price}
          />
        )}

        {displayInsurance && (
          <CheckoutListRow
            label={SUMMARY_DETAILS.INSURANCE_COVER}
            price={
              liabilityAmount ? Formatters.formatPrice(liabilityAmount) : FREE
            }
          />
        )}
      </Box>

      <Divider sx={{ display: isLastItem ? 'none' : 'block' }} />
    </>
  );
};

CheckoutListItem.propTypes = {
  basketItem: PropTypes.object,
  isLastItem: PropTypes.bool,
  showMoreDetails: PropTypes.bool,
  displayInsurance: PropTypes.bool,
};

const CheckoutPriceList = ({
  items = [],
  displayInsurance,
  showMoreDetails,
}) => (
  <>
    {items.map((basketItem, index) => (
      <CheckoutListItem
        key={basketItem.basketItemId}
        basketItem={basketItem}
        isLastItem={items.length === index + 1}
        showMoreDetails={showMoreDetails}
        displayInsurance={displayInsurance}
      />
    ))}
  </>
);

CheckoutPriceList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  displayInsurance: PropTypes.bool,
  showMoreDetails: PropTypes.bool,
};

export default CheckoutPriceList;
