export const FIELDS = {
  HELP_TYPE: { KEY: 'helpType' },
  PARCEL_NUMBER: { KEY: 'parcelNumber' },
  ISSUE_TYPE: { KEY: 'issueType' },
  CONTACT_NAME: { KEY: 'contactName', LABEL: 'Contact name' },
  EMAIL: { KEY: 'email', LABEL: 'Email address' },
  PHONE: { KEY: 'phone', LABEL: 'Phone number' },
  PARCEL_CONTENTS: { KEY: 'parcelContents', LABEL: 'Parcel contents' },
};

export const HELP_TYPES = {
  CHAT: 'chat',
  ISSUE: 'issue',
};

export const ISSUE_TYPES = {
  DAMAGED: 'damaged',
  NOT_ARRIVED: 'notArrived',
  MISSING_CONTENTS: 'missingContents',
};
