import Joi from 'joi';
import { set } from 'lodash';
import { PostcodeValidator } from '@dpdgroupuk/consumer-shipping-helper';
import { REGEX } from '../constants';
import * as STRINGS from '../constants/strings';
import {
  ACCOUNT_DETAILS_FIELDS,
  ADDRESS_LINE_1,
  ADDRESS_LINE_2,
  CITY,
  COMPANY_NAME,
  CONTACT_NAME,
  COUNTRY,
  COUNTY,
  EMAIL,
  EORI_NUMBER,
  FIELDS,
  PHONE_NUMBER,
  PID_NUMBER,
  POSTAL_ZIP_CODE,
  QUANTITY,
  UKIMS_NUMBER,
  VAT_NUMBER,
} from '../constants/forms';
import * as StringUtil from './strings';

export const required = value => (value ? undefined : STRINGS.REQUIRED);

export const strictRequired = Joi.required().empty('', null);

export const isPostcodeValid = (postcode, countryValue) =>
  PostcodeValidator.isPostcodeValid(postcode, countryValue);

export const validatePostcode = ({ value, helpers, countryValue }) => {
  const bfpoResult = bfpoValidation(value, helpers);

  // code property only exists if there is a JOI error
  if (bfpoResult && bfpoResult.code) {
    return bfpoResult;
  }

  if (!isPostcodeValid(value, countryValue)) {
    return helpers.error('postcode.country.invalid');
  }

  return value;
};

// Validator for Joi with react-final-form integration
export const validateWithJoi = (values, schema, options = {}) => {
  let errors = {};
  const { error } = schema.validate(values, {
    abortEarly: false,
    errors: { wrap: { label: false } },
    ...options,
  });
  if (error) {
    errors = error.details.reduce((all, cur) => {
      const allErrors = Object.assign({}, all);
      set(allErrors, cur.path.join('.'), cur.message);

      return allErrors;
    }, {});
  }

  return errors;
};

// NOTE: Somehow only this approach works for objects
const requiredObject = Joi.object().required().empty(null);

export const requireKeysSchema = (schema, requireKeys = []) => {
  const makeRequired = x => {
    if (x.type === 'object') {
      return x.concat(requiredObject);
    }

    return x.required().empty('', null);
  };

  return schema.fork(requireKeys, makeRequired);
};

export const getJoiFieldFlag = (schema, fieldName, validationFlag) => {
  // Get schema flags
  const fieldSchema = schema.extract(fieldName);

  // Check field validation flag
  return fieldSchema?._flags?.presence === validationFlag;
};

export const isFieldRequired = (schema, fieldName) =>
  getJoiFieldFlag(schema, fieldName, 'required');

const numberOfParcels = Joi.number().min(1).max(99).required().label(QUANTITY);
const totalWeight = Joi.number().greater(0).required();

export const bfpoValidation = (value, helpers) => {
  if (REGEX.BFPO_POSTCODES.some(regex => regex.test(value))) {
    return helpers.error('postcode.bfpo.invalid');
  }
  return value;
};

export const addressSchema = {
  street: Joi.string().max(35).required().label(ADDRESS_LINE_1),
  locality: Joi.string().max(35).allow(null, '').label(ADDRESS_LINE_2),
  town: Joi.string().max(35).required().label(CITY),
  county: Joi.string().max(35).allow(null, '').label(COUNTY),
  organisation: Joi.string().max(35).allow(null, '').label(COMPANY_NAME),
  countryCode: Joi.string().required().label(COUNTRY),
};

export const addressSchemaWithPostCode = countryValue => ({
  ...addressSchema,
  postcode: Joi.string()
    .max(8)
    .allow(null, '')
    .label(POSTAL_ZIP_CODE)
    .custom((value, helpers) =>
      validatePostcode({ value, helpers, countryValue })
    )
    .messages(STRINGS.CUSTOM_VALIDATION_ERROR_MESSAGES),
});

const contactSchema = {
  contactName: Joi.string().max(35).required().label(CONTACT_NAME),
  email: Joi.string()
    .pattern(REGEX.EMAIL_REGEX)
    .required()
    .max(100)
    .messages({
      'string.pattern.base': StringUtil.formatMessage(
        STRINGS.PLEASE_ENTER_VALID_$,
        STRINGS.EMAIL
      ),
    })
    .label(EMAIL),
  phoneNumber: Joi.string()
    .pattern(REGEX.COMMON_PHONE_NUMBER_REGEXP)
    .required()
    .max(15)
    .messages({
      'string.pattern.base': StringUtil.formatMessage(
        STRINGS.PLEASE_ENTER_VALID_$,
        STRINGS.PHONE_NUMBER
      ),
    })
    .label(PHONE_NUMBER),
  vatNumber: Joi.string().min(4).max(45).allow(null, '').label(VAT_NUMBER),
  eoriNumber: Joi.string().min(4).max(14).allow(null, '').label(EORI_NUMBER),
  pidNumber: Joi.string().min(4).max(45).allow(null, '').label(PID_NUMBER),
  ukimsNumber: Joi.string().length(32).allow(null, '').label(UKIMS_NUMBER),
};

export const accountDetailsSchema = Joi.object({
  [ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.FIRST_NAME.NAME]: Joi.string()
    .max(15)
    .required()
    .label(ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.FIRST_NAME.LABEL),
  [ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.LAST_NAME.NAME]: Joi.string()
    .max(15)
    .required()
    .label(ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.LAST_NAME.LABEL),
  [ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.EMAIL_ADDRESS.NAME]: Joi.string()
    .pattern(REGEX.EMAIL_REGEX)
    .required()
    .max(100)
    .label(ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.EMAIL_ADDRESS.LABEL)
    .messages({
      'string.pattern.base': StringUtil.formatMessage(
        STRINGS.PLEASE_ENTER_VALID_$,
        STRINGS.EMAIL
      ),
    }),
  [ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.PHONE_NUMBER.NAME]: Joi.string()
    .allow(null)
    .label(ACCOUNT_DETAILS_FIELDS.ACCOUNT_DETAILS.PHONE_NUMBER.LABEL),
});

export const addressBookSchema = countryValue =>
  Joi.object({
    address: Joi.object(addressSchemaWithPostCode(countryValue)),
    ...contactSchema,
  });

export const getNetworksSchema = (
  deliveryCountryValue,
  collectionCountryValue
) =>
  Joi.object({
    deliveryDetails: Joi.object({
      address: Joi.object({
        countryCode: Joi.string().required(),
        town: Joi.string().max(35).required(),
        county: Joi.string().max(35).allow(null, ''),
        postcode: Joi.string()
          .max(8)
          .required()
          .label(POSTAL_ZIP_CODE)
          .custom((value, helpers) =>
            validatePostcode({
              value,
              helpers,
              countryValue: deliveryCountryValue,
            })
          )
          .messages(STRINGS.CUSTOM_VALIDATION_ERROR_MESSAGES),
      }).required(),
    }).required(),
    collectionDetails: Joi.object({
      address: Joi.object({
        countryCode: Joi.string().required(),
        town: Joi.string().max(35).required(),
        county: Joi.string().max(35).allow(null, ''),
        postcode: Joi.string()
          .max(8)
          .required()
          .label(POSTAL_ZIP_CODE)
          .custom((value, helpers) =>
            validatePostcode({
              value,
              helpers,
              countryValue: collectionCountryValue,
            })
          )
          .messages(STRINGS.CUSTOM_VALIDATION_ERROR_MESSAGES),
      }).required(),
    }).required(),
    numberOfParcels,
    totalWeight,
  }).required();

export const voucherSchema = Joi.object({
  voucherCode: Joi.string()
    .min(3)
    .messages({
      'string.min': STRINGS.GREATER_OR_$_CHARACTERS(3),
    }),
});

export const trackParcelSchema = Joi.object({
  [FIELDS.PARCEL_NUMBER.KEY]: Joi.string()
    .pattern(REGEX.PARCEL_NUMBER_REGEXP)
    .concat(strictRequired)
    .empty('', null)
    .messages({
      'string.pattern.base': 'The parcel number must contain exactly 14 digits',
      'any.required': 'Parcel number is required',
    }),
  [FIELDS.POSTCODE_SEARCH.KEY]: Joi.string()
    .max(8)
    .allow(null, '')
    .label(FIELDS.POSTCODE_SEARCH.LABEL),
});

export const loginEmailSchema = Joi.object({
  [FIELDS.EMAIL.KEY]: Joi.string()
    .pattern(REGEX.EMAIL_REGEX)
    .concat(strictRequired)
    .max(100)
    .messages({
      'string.pattern.base': StringUtil.formatMessage(
        STRINGS.PLEASE_ENTER_VALID_$,
        STRINGS.EMAIL
      ),
      'any.required': STRINGS.REQUIRED,
      'string.max': STRINGS.LESS_OR_$_CHARACTERS(100),
    }),
});

export const isUKPostcodeValid = postcode =>
  REGEX.UK_POST_CODE_FULL.test(postcode);
