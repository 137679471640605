import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Box } from '@mui/material';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import ReduxifiedWizard from '../../../features/Wizard/ReduxifiedWizard';
import { VERIFICATION } from '../../../constants/analytics';
import termsImg from '../../../assets/images/termsImg.png';
import useBreakpoint from '../../../hooks/useBreakpoint';
import Step from '../components/Step';
import { useAuth } from '../../../features/Auth';
import TermsAndConditions from './Terms';
import VerifyPhone from './VerifyPhone';
import ConfirmPin from './VerifyPin';
import { SignUpSlice } from './slice';

const STEPS = {
  TERMS: 'terms',
  VERIFY_PHONE: 'verifyPhone',
  VERIFY_PIN: 'verifyPin',
};

function Onboarding() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const auth = useAuth();
  const isSmallScreen = useBreakpoint('lg');

  useEffect(() => {
    if (!state.verificationId || auth.currentSession?.uid) {
      navigate('/', { replace: true });
    }
  }, []);

  return (
    <Trackable
      interfaceId={VERIFICATION.INTERFACE_ID}
      loadId={VERIFICATION.LOAD}
    >
      <Box sx={{ flexGrow: 1, p: { xs: 3, md: 2 }, display: 'flex' }}>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <img
            src={termsImg}
            style={{ width: isSmallScreen ? '100%' : 'auto' }}
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            ml: { md: '78px' },
            justifyContent: 'center',
            width: { md: '500px' },
            maxWidth: { md: '500px' },
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ReduxifiedWizard
            isLazyMount={true}
            slice={SignUpSlice}
            sx={{ flexGrow: 1, display: 'flex', width: '100%' }}
          >
            <Step stepName={STEPS.TERMS} component={TermsAndConditions} />
            <Step stepName={STEPS.VERIFY_PHONE} component={VerifyPhone} />
            <Step stepName={STEPS.VERIFY_PIN} component={ConfirmPin} />
          </ReduxifiedWizard>
        </Box>
      </Box>
    </Trackable>
  );
}

export default Onboarding;
