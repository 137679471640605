import { useCallback, useEffect, useRef } from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import Wizard from './Wizard';
// import { useToaster } from '../CustomSnackbar';
// import * as M from '../../constants/strings';

const QUERY_STEP_FIELD = 'step';

export default function RouterWizard({ children, ...props }) {
  const wizardRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  // const toaster = useToaster();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlActiveNamedStep = searchParams.get(QUERY_STEP_FIELD);

  useEffect(() => {
    if (wizardRef && wizardRef.current) {
      if (
        urlActiveNamedStep &&
        wizardRef.current.currentNamedStep !== urlActiveNamedStep
      ) {
        wizardRef.current.goToNamedStep(urlActiveNamedStep);
      }
    }
  }, [urlActiveNamedStep]);

  useEffect(() => {
    if (!urlActiveNamedStep) {
      navigate(
        {
          pathname: location.pathname,
          search: createSearchParams({
            ...Object.fromEntries(searchParams),
            step: wizardRef.current?.currentNamedStep,
          }).toString(),
        },
        {
          state: location.state,
          replace: true,
        }
      );
    }
    return () => {};
  }, []);

  const handleSearchStepParam = useCallback(
    activeNamedStep => {
      setSearchParams(
        createSearchParams({
          ...Object.fromEntries(searchParams),
          step: activeNamedStep,
        }),
        {
          state: location.state,
        }
      );
    },
    [setSearchParams, searchParams]
  );

  const handleStepChange = useCallback(
    payload => {
      if (urlActiveNamedStep !== payload.activeNamedStep) {
        handleSearchStepParam(payload.activeNamedStep);
      }
      props.onStepChange && props.onStepChange(payload);
    },
    [props.onStepChange, urlActiveNamedStep, handleSearchStepParam]
  );

  // const handleComponentDidCatch = useCallback(() => {
  //   toaster.showError({
  //     body: M.UNABLE_TO_LOAD_THIS_PAGE_TRY_AGAIN,
  //   });

  //   handleSearchStepParam(wizardRef.current?.currentNamedStep);
  // }, [toaster]);

  return (
    <Wizard
      {...props}
      instance={ref => {
        wizardRef.current = ref;
        props.instance && props.instance(ref);
      }}
      onStepChange={handleStepChange}
      // onComponentDidCatch={handleComponentDidCatch}
    >
      {children}
    </Wizard>
  );
}

RouterWizard.propTypes = Wizard.propTypes;
