import moment from 'moment-timezone';

import { format } from 'date-fns';
import { DATE_FORMAT } from '../constants';

export const formattedDayOfTheWeekDayMonthShipmentDate = value =>
  value
    ? format(new Date(value), DATE_FORMAT.DAY_OF_WEEK_DAY_MONTH_FORMAT)
    : '';

export const getUKCurrentMomentTime = () =>
  moment().tz(DATE_FORMAT.EUROPE_LONDON_TIME_ZONE);

export const getNextWorkingDay = (date = moment()) => {
  let nextDay = moment(date).add(1, 'days');

  // Keep adding days if the next day is a weekend (Saturday or Sunday)
  while (nextDay.isoWeekday() > 5) {
    nextDay.add(1, 'days');
  }

  return nextDay;
};

export const addBusinessDays = (date, days) => {
  if (!date || !days) {
    return null;
  }
  let result = moment(date);
  while (days > 0) {
    result.add(1, 'days');
    if (result.day() !== 0 && result.day() !== 6) {
      days--;
    }
  }
  return result;
};
