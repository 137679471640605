import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { HttpStatusCode } from 'axios';

import { parcelsApis } from '../apis';
import {
  PARCEL_NUMBER_IS_NOT_VALID,
  PARCEL_CODE_IS_NOT_VALID,
  PLEASE_ENTER_VALID_$,
} from '../constants/strings';
import { ParcelUtil, StringUtil } from '../utils/';

const initialState = {
  status: 'idle',
  data: {},
};

const fetchParcel = createAsyncThunk(
  'parcel/fetchByNumber',
  async (parcelNumber, { rejectWithValue }) => {
    try {
      const response = await parcelsApis.getParcelByNumber(parcelNumber);

      return response.data;
    } catch (error) {
      if (error.response) {
        if (
          error.response.data.error.statusCode === HttpStatusCode.BadRequest
        ) {
          return rejectWithValue({
            ...error.response.data.error,
            message: `${PARCEL_NUMBER_IS_NOT_VALID} ${StringUtil.formatMessage(
              PLEASE_ENTER_VALID_$,
              'value'
            )}.`,
          });
        }

        return rejectWithValue(error.response.data.error);
      } else {
        throw error;
      }
    }
  }
);

const fetchParcelByCode = createAsyncThunk(
  'parcel/fetchByCode',
  async ({ parcelCode, postcode }, { rejectWithValue }) => {
    try {
      const response = await parcelsApis.getParcelByCode({
        parcelCode,
        postcode,
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        if (
          error.response.data.error.statusCode === HttpStatusCode.BadRequest
        ) {
          return rejectWithValue({
            ...error.response.data.error,
            message: `${PARCEL_CODE_IS_NOT_VALID} ${StringUtil.formatMessage(
              PLEASE_ENTER_VALID_$,
              'value'
            )}.`,
          });
        }

        return rejectWithValue(error.response.data.error);
      } else {
        throw error;
      }
    }
  }
);

const slice = createSlice({
  name: 'parcel',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchParcel.fulfilled, (state, { payload }) => {
        state.data[ParcelUtil.normalizeParcelNumber(payload.parcelNumber)] =
          payload;
        state.status = 'success';
      })
      .addCase(fetchParcel.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchParcel.rejected, state => {
        state.status = 'failure';
      })
      .addCase(fetchParcelByCode.fulfilled, (state, { payload }) => {
        state.data[ParcelUtil.normalizeParcelNumber(payload.parcelNumber)] =
          payload;
        state.status = 'success';
      });
  },
});

const getParcelState = state => state.parcel;

const getParcel = parcelNumber =>
  createSelector(getParcelState, state => ({
    data: state.data[parcelNumber],
    status: state.status,
  }));

export default {
  reducer: slice.reducer,
  actions: {
    fetchParcel,
    fetchParcelByCode,
  },
  selectors: {
    getParcel,
  },
};
