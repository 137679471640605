export const formatMessage = (template, ...args) =>
  template.replace(/{(\d)}/g, (s, num) => args[num]);

export const replaceLineBreakWithBr = string => {
  if (typeof string !== 'string') {
    return null;
  }
  const splittedString = string.split('\n');
  return splittedString.length > 1
    ? splittedString.map((item, index) => (
        <span key={index}>
          {item}
          <br />
        </span>
      ))
    : string;
};

export const removeExtraSpaces = string => string?.replace(/ +(?= )/g, '');

export const replaceLetters = value =>
  value ? value.replace(/[^0-9]/g, '') : '';

export const replaceFirstZero = value =>
  value ? value.replace(/^0(?!\.)/, '') : '';

const replaceLeadingZeros = value => value.replace(/^0+(?=\d)/, ''); // removes all leading zeros except one before a decimal

export const formatDecimalString = value => {
  if (!value) {
    return value;
  }

  let result = replaceLeadingZeros(value.toString())
    .replace(/^\./, '0.') // adds a zero at the start if the value begins with a dot
    .replace(/\.(?!\d)/, ''); // removes the dot if the value ends with a dot

  return result !== '0' ? result : '';
};

export const trimAllSpaces = value => value.replace(/\s/g, '');

export const trimFirstSpace = value => {
  if (typeof value === 'string') {
    return value.replace(/^\s+/, '');
  }
  return value;
};

export const restrictToDigitsAndLimit = (value, limit = 3) =>
  value ? replaceFirstZero(replaceLetters(value)).slice(0, limit) : '';
