import { Divider, styled, Switch, Typography, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { COMMUNICATION_PREFERENCES_SECTION } from '../../../../constants/strings';
import {
  getCommunicationPreferences,
  isAccountDetailsLoading,
  isPhoneNumberExistSelector,
  updateCommunicationPreferences,
} from '../../../../redux/userSlice';
import { useCustomSnackbar } from '../../../../features/CustomSnackbar';
import { ACCOUNT_DETAILS_FIELDS } from '../../../../constants/forms';
import AccountSectionLayout from './AccountSectionLayout';

const StyledSwitchBox = styled(Box)(() => ({
  margin: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: '8px',
}));

const StyledTypographyLabel = styled(Typography)(() => ({
  fontSize: '13px',
  fontWeight: 400,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  lineHeight: '18px',
  letterSpacing: '0.16px',
}));

const CommunicationPreferences = () => {
  const dispatch = useDispatch();
  const { showError, showDefault } = useCustomSnackbar();
  const { notifyByEmail, notifyBySms } = useSelector(state =>
    getCommunicationPreferences(state)
  );
  const communicationPreferencesUpdateLoading = useSelector(state =>
    isAccountDetailsLoading(state)
  );
  const isPhoneNumberExist = useSelector(state =>
    isPhoneNumberExistSelector(state)
  );

  const handleOnChange = useCallback(
    async ({ target }) => {
      if (!target) {
        return;
      }
      try {
        await dispatch(
          updateCommunicationPreferences({ [target.name]: target.checked })
        ).unwrap();
        showDefault({
          message: COMMUNICATION_PREFERENCES_SECTION.SNACKBAR_MESSAGES.SUCCESS,
        });
      } catch (e) {
        showError({
          message: COMMUNICATION_PREFERENCES_SECTION.SNACKBAR_MESSAGES.FAILED,
        });
      }
    },
    [notifyByEmail, notifyBySms]
  );

  return (
    <AccountSectionLayout>
      <Box sx={{ paddingX: 2, paddingTop: 2, paddingBottom: 1 }}>
        <Typography
          variant='typography/subtitle2'
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '158%',
            letterSpacing: '0.1px',
          }}
        >
          {COMMUNICATION_PREFERENCES_SECTION.COMMUNICATION_PREFERENCES}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ padding: 2 }}>
        <Typography
          variant='typography/subtitle2'
          sx={{
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 400,
            letterSpacing: '0.15px',
            lineHeight: '24px',
          }}
        >
          {COMMUNICATION_PREFERENCES_SECTION.MARKETING_COMMUNICATION.TITLE}
        </Typography>
        <Typography
          variant='body2'
          sx={{
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 400,
            letterSpacing: '0.4px',
            lineHeight: '166%',
            paddingBottom: 1,
          }}
        >
          {
            COMMUNICATION_PREFERENCES_SECTION.MARKETING_COMMUNICATION
              .DESCRIPTION
          }
        </Typography>
        <StyledSwitchBox>
          <StyledTypographyLabel>
            {ACCOUNT_DETAILS_FIELDS.COMMUNICATION_PREFERENCES.EMAIL.LABEL}
          </StyledTypographyLabel>
          <Switch
            name={ACCOUNT_DETAILS_FIELDS.COMMUNICATION_PREFERENCES.EMAIL.NAME}
            data-testid={
              ACCOUNT_DETAILS_FIELDS.COMMUNICATION_PREFERENCES.EMAIL.NAME
            }
            defaultChecked={notifyByEmail}
            onChange={handleOnChange}
            disabled={communicationPreferencesUpdateLoading}
          />
        </StyledSwitchBox>
        <StyledSwitchBox>
          <StyledTypographyLabel>
            {ACCOUNT_DETAILS_FIELDS.COMMUNICATION_PREFERENCES.SMS.LABEL}
          </StyledTypographyLabel>
          <Switch
            name={ACCOUNT_DETAILS_FIELDS.COMMUNICATION_PREFERENCES.SMS.NAME}
            data-testid={
              ACCOUNT_DETAILS_FIELDS.COMMUNICATION_PREFERENCES.SMS.NAME
            }
            defaultChecked={isPhoneNumberExist && notifyBySms}
            checked={isPhoneNumberExist && notifyBySms}
            onChange={handleOnChange}
            disabled={
              !isPhoneNumberExist || communicationPreferencesUpdateLoading
            }
          />
        </StyledSwitchBox>
        {/* Tracking update will be an enhancment for later on */}
        {/* <Divider sx={{ marginY: 1 }} /> */}
        {/* <Typography
          variant='typography/subtitle2'
          sx={{
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontWeight: 400,
            letterSpacing: '0.15px',
            lineHeight: '24px',
            paddingTop: 0.5,
          }}
        >
          {COMMUNICATION_PREFERENCES_SECTION.TRACKING_UPDATES.TITLE}
        </Typography>
        <Typography
          variant='body2'
          sx={{
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: 400,
            letterSpacing: '0.4px',
            lineHeight: '166%',
            paddingBottom: 1.5,
          }}
        >
          {COMMUNICATION_PREFERENCES_SECTION.TRACKING_UPDATES.DESCRIPTION}
        </Typography> */}
        {/* <StyledSwitchBox>
          <StyledTypographyLabel>
            {ACCOUNT_DETAILS_FIELDS.COMMUNICATION_PREFERENCES.UPDATE.LABEL}
          </StyledTypographyLabel>
          <Switch
            name={ACCOUNT_DETAILS_FIELDS.COMMUNICATION_PREFERENCES.UPDATE.NAME}
            data-testid={
              ACCOUNT_DETAILS_FIELDS.COMMUNICATION_PREFERENCES.UPDATE.NAME
            }
            defaultChecked={allowTrackingUpdates}
            onChange={handleOnChange}
            disabled={communicationPreferencesUpdateLoading}
          />
        </StyledSwitchBox> */}
      </Box>
    </AccountSectionLayout>
  );
};

export default CommunicationPreferences;
