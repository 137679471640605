import { createSelector } from '@reduxjs/toolkit';

import { OrderSliceSelectors } from '../../slice';
import { ExportReasonsSelectors } from '../../../../redux/exportReasonSlice';
import { InvoiceHelpers } from '../../../../helpers';
import { convertObjectKeysToArray } from '../../../../utils/object';
import { getAddressDetailsRequiredFields } from '../../../../helpers/shipment';
import { getActivePickupPoints, getAllowedFields } from './helpers';

export const getRequiredFields = createSelector(
  OrderSliceSelectors.getValues,
  getAddressDetailsRequiredFields
);

export const getRequiredData = createSelector(
  getRequiredFields,
  requiredFields => ({
    requiredFields,
    requiredKeys: convertObjectKeysToArray(requiredFields),
  })
);

export const getAvailableFields = createSelector(
  [
    OrderSliceSelectors.getValues,
    OrderSliceSelectors.getReferences,
    (state, currentSession) => currentSession,
  ],
  getAllowedFields
);

export const getPickupPoints = createSelector(
  OrderSliceSelectors.getReferences,
  getActivePickupPoints
);

export const getExportReasonsKeyValue = createSelector(
  ExportReasonsSelectors.getExportReasons,
  OrderSliceSelectors.getValues,
  InvoiceHelpers.filterExportReasons
);
