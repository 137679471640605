import {
  Alert,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { Circle } from '@mui/icons-material';
import { BUSINESS_ISSUE } from '../../../constants/strings';

const IncorrectOrderAlert = ({ errors }) => (
  <Box sx={{ p: 2, pb: 0 }}>
    <Alert icon={false} severity='error' sx={{ color: '#5F2120' }}>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 'bold',
          mb: 1,
        }}
      >
        {BUSINESS_ISSUE}
      </Typography>
      <List>
        {errors.map(error => (
          <ListItem key={error} sx={{ padding: 0 }}>
            <ListItemIcon sx={{ minWidth: '15px' }}>
              <Circle fontSize='small' sx={{ width: '5px' }} />
            </ListItemIcon>
            <ListItemText sx={{ margin: 0 }}>
              <Typography variant='body2'>{error}</Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Alert>
  </Box>
);

export default IncorrectOrderAlert;
