import { useCallback, useMemo } from 'react';
import WizardForm from '../../../features/Wizard/Form';
import { Validators } from '../../../utils';
import { ShipmentValidator } from '../validators';

const OrderForm = ({ children, onSubmit, context, requiredKeys, ...props }) => {
  const schema = useMemo(
    () =>
      Validators.requireKeysSchema(
        ShipmentValidator.shipmentSchema(context),
        requiredKeys
      ),
    [requiredKeys, context]
  );

  const validate = useCallback(
    values =>
      Validators.validateWithJoi(values, schema, {
        allowUnknown: true,
      }),
    [schema]
  );

  return (
    <WizardForm
      onSubmit={onSubmit}
      validate={validate}
      validateOnBlur
      {...props}
    >
      {children}
    </WizardForm>
  );
};

export default OrderForm;
