import apis from './apis';

export const getParcels = ({ searchPage, searchPageSize }) =>
  apis.get('parcels/filter', {
    params: {
      searchPage,
      searchPageSize,
    },
  });

export const getParcelByNumber = parcelNumber =>
  apis.get('parcels/', {
    params: {
      parcelNumber,
    },
  });

export const getParcelCodeByParcelNumber = ({ parcelNumber, postcode }) =>
  apis.get('parcels/find', {
    params: {
      parcelNumber,
      postcode,
    },
  });

export const getParcelByCode = ({ parcelCode, postcode }) =>
  apis.get(`parcels/${parcelCode}`, {
    params: {
      postcode,
    },
  });
