import { get } from 'lodash';
import { APIS_BASE_URL } from '../constants/env';
import { FORM, INVOICE_CONSTANTS, STRINGS } from '../constants';
import { AddressUtil, NetworkUtil } from '../utils/';

export const getShipmentInvoiceDownloadUrl = shipmentId =>
  `${APIS_BASE_URL}/shipments/${shipmentId}/invoice`;

export const filterExportReasons = (exportReasons = [], values = {}) => {
  const networkCode = get(values, FORM.SHIPMENT_FIELDS.NETWORK_CODE.KEY, '');

  if (
    INVOICE_CONSTANTS.EXCLUDE_REASONS.includes(networkCode) ||
    (AddressUtil.isCountryIE(
      get(values, FORM.SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY, '')
    ) &&
      NetworkUtil.removeBusinessUnit(networkCode) === '11')
  ) {
    return INVOICE_CONSTANTS.DEFAULT_REASONS;
  }

  return exportReasons.map(({ code: value, description: label }) => ({
    label,
    value,
  }));
};

export const sortCurrencies = (currencies = []) =>
  currencies.sort((a, b) => {
    if (a.currencyCode === STRINGS.GBP) return -1;
    if (b.currencyCode === STRINGS.GBP) return 1;
    if (a.currencyCode === STRINGS.EUR) return -1;
    if (b.currencyCode === STRINGS.EUR) return 1;

    return a.currencyCode > b.currencyCode ? 1 : -1;
  });

export const getCurrenciesKeyValue = (currencies = []) =>
  currencies.map(({ currencyCode }) => ({
    label: currencyCode,
    value: currencyCode,
  }));
