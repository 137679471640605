import { useCallback, useState } from 'react';
import { Field, Form } from 'react-final-form';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { TextField as TextFieldRFF } from 'mui-rff';
import { Search } from '@mui/icons-material';
import { get, flow } from 'lodash';
import { useDispatch } from 'react-redux';
import {
  CHAT_WITH_US,
  CHOOSE_ONE_OF_OPTIONS,
  DAMAGED,
  ENTER_YOUR_PARCEL_NUMBER,
  GET_ANSWER_WITH_CHAT,
  HOW_CAN_WE_HELP,
  MISSING_CONTENTS,
  MY_PARCEL_DAMAGED,
  MY_PARCEL_HAS_CONTENTS_MISSING,
  MY_PARCEL_NOT_ARRIVED,
  NOT_ARRIVED,
  PARCEL_ISSUE,
  PARCEL_REFERENCE_NUMBER,
  TELL_ABOUT_DAMAGE,
  WE_CAN_HELP_PARCEL_ISSUE,
  YOUR_CONTACT_DETAILS,
} from '../../constants/strings';
import ActionButton from '../../components/ActionButton';
import Loader from '../../features/Loader';
import parcelSlice from '../../redux/parcelSlice';
import OrderDetails from '../../components/OrderDetails';
import { replaceLetters, trimAllSpaces } from '../../utils/strings';
import { FIELDS, HELP_TYPES, ISSUE_TYPES } from './constants';

const Help = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  // const { fetchParcel, isParcelFound } = useParcelSearch();
  const [parcelLoadAvailable, setParcelLoadAvailable] = useState(false);
  const [parcelData, setParcelData] = useState();

  const fetchParcel = useCallback(
    async ({ parcelNumber }) => {
      const parcel = await dispatch(
        parcelSlice.actions.fetchParcel(parcelNumber)
      ).unwrap();
      setParcelData(parcel);

      return parcel;
    },
    [dispatch]
  );

  // const isParcelIssueSelected =

  return (
    <Box
      sx={{
        py: { xs: 2 },
        p: { md: 4 },
        background: theme.palette.primary.pageBackground,
        flexGrow: 1,
      }}
    >
      <Grid
        container
        sx={{
          justifyContent: 'center',
          mt: 1,
        }}
      >
        <Grid item>
          <Typography variant='h2' sx={{ m: 2, mt: 0 }}>
            {HOW_CAN_WE_HELP}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0} alignItems='center' justifyContent='center'>
        <Grid item xs={12} md={9} lg={7} xl={6}>
          <Form onSubmit={() => {}}>
            {({ values }) => {
              const isParcelIssueSelected =
                get(values, FIELDS.HELP_TYPE.KEY) === HELP_TYPES.ISSUE;
              const issueDetailsAvailable =
                values.contactName && values.email && values.phone;

              return (
                <>
                  <Paper sx={{ p: 2, mb: 2 }}>
                    <Typography variant='h3' sx={{ mb: 1 }}>
                      {CHOOSE_ONE_OF_OPTIONS}
                    </Typography>
                    <Field name={FIELDS.HELP_TYPE.KEY}>
                      {({ input }) => (
                        <Grid
                          container
                          spacing={2}
                          sx={{ mt: 1, alignItems: 'stretch' }}
                        >
                          <Grid container item md={6} xs={12}>
                            <ActionButton
                              selected={input.value === HELP_TYPES.CHAT}
                              title={CHAT_WITH_US}
                              bodyText={GET_ANSWER_WITH_CHAT}
                              cardOnclick={() => {
                                input.onChange(HELP_TYPES.CHAT);
                              }}
                              disabled
                              additionalInfo={false}
                            />
                          </Grid>
                          <Grid container item md={6} xs={12}>
                            <ActionButton
                              selected={input.value === HELP_TYPES.ISSUE}
                              title={PARCEL_ISSUE}
                              bodyText={WE_CAN_HELP_PARCEL_ISSUE}
                              additionalInfo={false}
                              cardOnclick={() => {
                                input.onChange(HELP_TYPES.ISSUE);
                              }}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Field>
                  </Paper>
                  {isParcelIssueSelected && (
                    <Paper sx={{ p: 2, mb: 2 }}>
                      <Typography variant='h3' sx={{ mb: 1 }}>
                        {ENTER_YOUR_PARCEL_NUMBER}
                      </Typography>
                      <Box sx={{ mt: 3 }}>
                        <Field name={FIELDS.PARCEL_NUMBER.KEY}>
                          {({ input }) => (
                            <TextField
                              label={PARCEL_REFERENCE_NUMBER}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <IconButton>
                                      <Search
                                        onClick={() =>
                                          setParcelLoadAvailable(true)
                                        }
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              onKeyDown={event => {
                                if (event.key === 'Enter') {
                                  event.preventDefault();
                                  event.target.blur();
                                  setParcelLoadAvailable(true);
                                }
                              }}
                              onChange={e => {
                                input.onChange(e);
                                setParcelLoadAvailable(false);
                              }}
                              fieldProps={{
                                parse: flow([trimAllSpaces, replaceLetters]),
                              }}
                            />
                          )}
                        </Field>
                      </Box>
                    </Paper>
                  )}
                  {isParcelIssueSelected && parcelLoadAvailable && (
                    <Loader
                      promiseFn={fetchParcel}
                      parcelNumber={values.parcelNumber}
                    >
                      {parcel => (
                        <OrderDetails parcel={parcel} showBackButton={false} />
                      )}
                    </Loader>
                  )}
                  {parcelData && (
                    <Paper sx={{ p: 2, mb: 2, mt: 2 }}>
                      <Typography variant='h3' sx={{ mb: 1 }}>
                        {ENTER_YOUR_PARCEL_NUMBER}
                      </Typography>
                      <Field name={FIELDS.ISSUE_TYPE.KEY}>
                        {({ input }) => (
                          <Grid
                            container
                            spacing={2}
                            sx={{ mt: 1, alignItems: 'stretch' }}
                          >
                            <Grid container item md={4} xs={12}>
                              <ActionButton
                                selected={input.value === ISSUE_TYPES.DAMAGED}
                                title={DAMAGED}
                                bodyText={MY_PARCEL_DAMAGED}
                                cardOnclick={() => {
                                  input.onChange(ISSUE_TYPES.DAMAGED);
                                }}
                                additionalInfo={false}
                              />
                            </Grid>
                            <Grid container item md={4} xs={12}>
                              <ActionButton
                                selected={
                                  input.value === ISSUE_TYPES.NOT_ARRIVED
                                }
                                title={NOT_ARRIVED}
                                bodyText={MY_PARCEL_NOT_ARRIVED}
                                cardOnclick={() => {
                                  input.onChange(ISSUE_TYPES.NOT_ARRIVED);
                                }}
                                additionalInfo={false}
                              />
                            </Grid>
                            <Grid container item md={4} xs={12}>
                              <ActionButton
                                selected={
                                  input.value === ISSUE_TYPES.MISSING_CONTENTS
                                }
                                title={MISSING_CONTENTS}
                                bodyText={MY_PARCEL_HAS_CONTENTS_MISSING}
                                cardOnclick={() => {
                                  input.onChange(ISSUE_TYPES.MISSING_CONTENTS);
                                }}
                                additionalInfo={false}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Field>
                    </Paper>
                  )}
                  {values.issueType && (
                    <>
                      <Paper sx={{ p: 2, mb: 2, mt: 2 }}>
                        <Typography variant='h3' sx={{ mb: 1 }}>
                          {YOUR_CONTACT_DETAILS}
                        </Typography>
                        <Grid xs={12} sx={{ mt: 3 }}>
                          <TextFieldRFF
                            name={FIELDS.CONTACT_NAME.KEY}
                            label={FIELDS.CONTACT_NAME.LABEL}
                          />
                        </Grid>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                          <Grid item xs={12} md={6}>
                            <TextFieldRFF
                              name={FIELDS.EMAIL.KEY}
                              label={FIELDS.EMAIL.LABEL}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextFieldRFF
                              name={FIELDS.PHONE.KEY}
                              label={FIELDS.PHONE.LABEL}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                      <Paper
                        sx={{
                          p: 2,
                          mb: 2,
                          mt: 2,
                          opacity: issueDetailsAvailable ? 1 : 0.4,
                        }}
                      >
                        <Typography variant='h3' sx={{ mb: 1 }}>
                          {TELL_ABOUT_DAMAGE}
                        </Typography>
                        <Grid xs={12} sx={{ mt: 3 }}>
                          <TextFieldRFF
                            name={FIELDS.PARCEL_CONTENTS.KEY}
                            label={FIELDS.PARCEL_CONTENTS.LABEL}
                            disabled={!issueDetailsAvailable}
                          />
                        </Grid>
                      </Paper>
                    </>
                  )}
                </>
              );
            }}
          </Form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Help;
