import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, includes, set } from 'lodash';
import { Grid, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router';
import {
  OrderPriceAccumulator,
  ShipmentPrice,
} from '@dpdgroupuk/consumer-shipping-helper';
import { format } from 'date-fns';
import useAnalytics from '../../utils/useAnalytics';
import { getBasketItemsWithPrice } from '../../utils/basketItem';
import { getReceiverContactName } from '../../helpers/shipment';
import { BASKET } from '../../constants/analytics';
import {
  ADDRESSES_SECTION,
  EMPTY_CARD,
  FAILED_TO_DELETE_BASKET_ITEM,
  PARCEL_TO_$,
  PROCESS_$_SHIPMENT,
  PROCESS_TO_CHECKOUT,
  WANT_TO_REMOVE_$,
  YOUR_CART,
} from '../../constants/strings';
import BasketItemsGroupsList from '../../components/BasketItemsGroupsList';
import { SummaryDetails } from '../../components/SummaryDetails';
import Voucher from '../../features/Voucher';
import SalesConditions from '../../components/SalesConditions';
import InformationCollecting from '../../components/InformationCollecting';
import ConfirmDialog from '../../components/modals/ConfirmModal';
import useBreakpoint from '../../hooks/useBreakpoint';
import { BasketActions, BasketSelectors } from '../../redux/basket';
import PurchaseWidget from '../../components/PurchaseWidget';
import { useOverlay } from '../../features/Overlay';
import { useCustomSnackbar } from '../../features/CustomSnackbar';
import { SHIPMENT_FIELDS } from '../../constants/forms';
import ConfigSlice from '../../redux/configSlice';
import Loader from '../../features/Loader';
import { POINT_TYPE, STRINGS } from '../../constants';
import { countriesSelectors } from '../../redux/countriesSlice';
import { validateFullShipmentSync } from '../Order/helpers/shipment';
import BasketCard from './components/BasketCard';
import BasketViewSlice, { validateCollectionDateAsync } from './slice';

const StyledGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'sticky',
  top: theme.spacing(2),
  height: '100%',
  marginTop: theme.spacing(4),
}));

const Basket = () => {
  const dispatch = useDispatch();
  const basketId = useSelector(BasketSelectors.getBasketId);
  const basketItems = useSelector(BasketSelectors.getBasketItems);
  const checkoutList = useSelector(BasketViewSlice.selectors.getCheckoutList);
  const threeDSecureData = useSelector(
    BasketViewSlice.selectors.getThreeDSecureData
  );
  const [basketErrors, setBasketErrors] = useState({});
  const countries = useSelector(countriesSelectors.getCountries);
  const defaultCutOff = useSelector(
    ConfigSlice.selectors.getCollectionCutOffConfig
  );

  const { terms: termsLink } = useSelector(
    ConfigSlice.selectors.getLinksConfig
  );
  const { voucher: displayVoucher } = useSelector(
    ConfigSlice.selectors.getFeatureConfig
  );
  const priceConfig = useSelector(ConfigSlice.selectors.getPriceConfig);

  const isSmallScreen = useBreakpoint('sm');
  const theme = useTheme();
  const { setInterfaceId, Trackable } = useAnalytics();
  const [acceptTerms, setAcceptTerms] = useState(false);
  const references = useSelector(BasketViewSlice.selectors.getBasketReferences);
  const overlay = useOverlay();
  const snackbar = useCustomSnackbar();
  const navigate = useNavigate();
  const [voucher, setVoucher] = useState();
  const [selectedBasketItem, setSelectedBasketItem] = useState();
  const receiverContactName = getReceiverContactName(
    selectedBasketItem?.shipment
  );
  const parcels = get(
    selectedBasketItem,
    `shipment.${SHIPMENT_FIELDS.PARCELS.KEY}`,
    []
  );

  const isCheckoutButtonDisabled = !acceptTerms || !checkoutList.length;

  const onAddToCheckout = useCallback(
    basketItemId => {
      dispatch(
        BasketViewSlice.actions.setCheckoutList([...checkoutList, basketItemId])
      );
    },
    [checkoutList]
  );

  const onRemoveFromCheckout = useCallback(
    basketItemIdToRemove => {
      const newCheckoutList = checkoutList.filter(
        basketItemId => basketItemId !== basketItemIdToRemove
      );
      dispatch(BasketViewSlice.actions.setCheckoutList(newCheckoutList));
    },
    [checkoutList]
  );

  const onDeleteBasketItem = useCallback(async () => {
    try {
      overlay.show();
      setSelectedBasketItem(null);
      await dispatch(
        BasketActions.deleteBasketItem(selectedBasketItem.basketItemId)
      ).unwrap();
      onRemoveFromCheckout(selectedBasketItem.basketItemId);
    } catch (e) {
      snackbar.showError({
        message: FAILED_TO_DELETE_BASKET_ITEM,
      });
    } finally {
      overlay.hide();
    }
  }, [selectedBasketItem]);

  const checkoutListPrice = useMemo(() => {
    // totals section
    const totals = checkoutList.reduce((acc, id) => {
      const basketItem = basketItems.find(
        basketItem => basketItem.basketItemId === id
      );
      const reference = references[basketItem?.basketItemId];
      if (!reference || !basketItem) {
        return acc;
      }
      return OrderPriceAccumulator.aggregate(
        acc,
        id,
        ShipmentPrice.calculateShipmentPrice(
          basketItem.shipment,
          reference.networkPrice,
          priceConfig
        )
      );
    }, new OrderPriceAccumulator());

    // voucher section
    if (voucher) {
      totals.applyVoucherDiscount(voucher);
    }

    return totals.toViewJSON(2);
  }, [checkoutList, references, voucher, priceConfig, basketItems]);

  const basketItemsWithPrice = getBasketItemsWithPrice(
    checkoutList,
    basketItems,
    checkoutListPrice
  );

  const handleCheckoutSubmit = useCallback(
    async payload => {
      try {
        overlay.show();
        const purchaseData = {
          amount: checkoutListPrice.totalIncVatAmount,
          paymentNonce: payload.nonce,
          vendorRef: payload.type,
          voucherId: voucher && voucher.voucherId,
          diaryDate: format(new Date(), 'dd/MM/yyyy'),
        };
        const { orderId, shipments } = await dispatch(
          BasketActions.checkout({
            basketItemIds: checkoutList,
            purchaseData,
          })
        ).unwrap();

        if (shipments.length === 1) {
          snackbar.showSuccess({
            // put correct message later
            message: 'Shipment have been successfully created',
          });

          // navigate to shipments/:shipmentId
          navigate(`/shipments/${shipments[0].shipmentId}`, {
            replace: true,
          });
        } else {
          snackbar.showSuccess({
            message: `${shipments.length} shipment(s) have been successfully created`,
          });

          navigate(`/orders/${orderId}`, {
            replace: true,
          });
        }
      } catch (error) {
        if (!error.details) {
          error.details = [error];
        }
        error.details.forEach(detail => {
          snackbar.showError({
            autoHideDuration: null,
            message: detail.message,
          });
        });
      } finally {
        overlay.hide();
      }
    },
    [checkoutList, voucher, checkoutListPrice]
  );

  const getDestinationCountryCode = (basketItems, checkoutList) => {
    if (!Array.isArray(basketItems) || !Array.isArray(checkoutList))
      return STRINGS.GB;

    const matchingItems = basketItems.filter(item =>
      checkoutList.includes(item.basketItemId)
    );

    for (const matchingItem of matchingItems) {
      const countryCode = get(
        matchingItem,
        SHIPMENT_FIELDS.DESTINATION_COUNTRY.KEY,
        STRINGS.GB
      );

      if (countryCode && countryCode !== STRINGS.GB) {
        return countryCode;
      }
    }
    return STRINGS.GB;
  };

  const destinationCountryCode = getDestinationCountryCode(
    basketItems,
    checkoutList
  );

  useEffect(() => {
    const validateBasketItems = async () => {
      setInterfaceId(BASKET.INTERFACE_ID);

      const initialBasketErrors = {};
      const defaultCheckoutList = [];

      for (const basketItem of basketItems) {
        const shipment = basketItem.shipment;

        const errors = validateFullShipmentSync(shipment, countries);

        if (
          !errors.includes(STRINGS.DATE_CANNOT_BE_IN_THE_PAST) &&
          get(shipment, SHIPMENT_FIELDS.COLLECT_FROM.KEY) === POINT_TYPE.DOOR
        ) {
          // Async validation for collection date
          try {
            const isValidCollectionDate = await dispatch(
              validateCollectionDateAsync({ values: shipment, defaultCutOff })
            ).unwrap();

            if (!isValidCollectionDate) {
              errors.push(STRINGS.INVALID_COLLECTION_DATE);
            }
          } catch (error) {
            snackbar.showError({
              // TODO: put correct message later
              message: 'Error validating collection date',
            });
          }
        }

        set(initialBasketErrors, basketItem.basketItemId, errors);

        if (errors.length === 0) {
          defaultCheckoutList.push(basketItem.basketItemId);
        }
      }

      setBasketErrors(initialBasketErrors);

      dispatch(BasketViewSlice.actions.setCheckoutList(defaultCheckoutList));
    };

    validateBasketItems();
  }, []);

  const loadReferences = useCallback(
    ({ basketId }) =>
      dispatch(
        BasketViewSlice.actions.fetchBasketReferences(basketId)
      ).unwrap(),
    []
  );

  return (
    <Trackable loadId={BASKET.LOAD}>
      <ConfirmDialog
        open={!!selectedBasketItem}
        onConfirm={onDeleteBasketItem}
        onClose={() => setSelectedBasketItem(null)}
        title={ADDRESSES_SECTION.DELETE_MODAL.TITLE}
        confirmButtonText={ADDRESSES_SECTION.DELETE_MODAL.DELETE_BUTTON}
        containerStyle={{
          maxWidth: isSmallScreen ? '100%' : '400px',
        }}
      >
        {WANT_TO_REMOVE_$(PARCEL_TO_$(receiverContactName, parcels.length))}
      </ConfirmDialog>
      <Loader promiseFn={loadReferences} basketId={basketId}>
        {references => (
          <Grid
            sx={{
              background: theme.palette.primary.pageBackground,
              pb: 4,
              flexGrow: 1,
            }}
          >
            <Grid
              container
              sx={{
                justifyContent: 'center',
                mt: 1,
              }}
            >
              <Grid item>
                <Typography variant='h2' sx={{ m: 2 }} color='primary.main'>
                  {YOUR_CART}
                </Typography>
              </Grid>
            </Grid>

            <Grid container alignItems='center' justifyContent='center'>
              {basketItems.length ? (
                <Grid
                  container
                  spacing={2}
                  sx={{ mt: 0 }}
                  xs={12}
                  md={8}
                  justifyContent='center'
                >
                  <Grid item xs={12} md={8}>
                    <BasketItemsGroupsList
                      basketItems={basketItems}
                      basketItemComponent={({ basketItem }) => (
                        <BasketCard
                          references={references[basketItem.basketItemId]}
                          key={basketItem.basketItemId}
                          basketItem={basketItem}
                          onRemove={() => setSelectedBasketItem(basketItem)}
                          isInCheckoutList={includes(
                            checkoutList,
                            basketItem.basketItemId
                          )}
                          onAddToCheckout={onAddToCheckout}
                          onRemoveFromCheckout={onRemoveFromCheckout}
                          errors={basketErrors[basketItem.basketItemId]}
                        />
                      )}
                    />
                    {displayVoucher && isSmallScreen && (
                      <Voucher voucher={voucher} setVoucher={setVoucher} />
                    )}
                    <SalesConditions
                      onAcceptTerms={setAcceptTerms}
                      salesConditionsLink={termsLink}
                    />
                    <InformationCollecting />
                  </Grid>
                  <StyledGrid item xs={12} md={4} sx={{ mt: 0, p: 0 }}>
                    <SummaryDetails
                      price={checkoutListPrice}
                      basketItemsWithPrice={basketItemsWithPrice}
                      showMoreDetails={false}
                    />
                    {displayVoucher && !isSmallScreen && (
                      <Voucher voucher={voucher} setVoucher={setVoucher} />
                    )}
                    <PurchaseWidget
                      basketId={basketId}
                      threeDSecure={threeDSecureData}
                      amount={checkoutListPrice.totalIncVatAmount.toString()}
                      handleCheckout={handleCheckoutSubmit}
                      sx={{ width: '100%' }}
                      disabled={isCheckoutButtonDisabled}
                    >
                      {checkoutList.length > 0
                        ? PROCESS_$_SHIPMENT(checkoutList.length)
                        : PROCESS_TO_CHECKOUT}
                    </PurchaseWidget>
                  </StyledGrid>
                </Grid>
              ) : (
                <Typography sx={{ mt: 1, color: 'text.secondary' }}>
                  {EMPTY_CARD.toUpperCase()}
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </Loader>
    </Trackable>
  );
};

export default Basket;
