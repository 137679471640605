import { useCallback, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { Button, useMediaQuery, useTheme } from '@mui/material';

const useCustomSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const anchorOrigin = useMemo(
    () =>
      isMobile
        ? {
            vertical: 'top',
            horizontal: 'center',
          }
        : {
            vertical: 'bottom',
            horizontal: 'left',
          },
    [isMobile]
  );

  const getTestId = variant => ({
    'data-testid': `snackbar-${variant}`,
  });

  const showSnackbar = ({
    message,
    variant = 'default',
    autoHideDuration = 3000,
    actionButtonText = 'Close',
    ...props
  }) => {
    enqueueSnackbar(message, {
      variant,
      autoHideDuration,
      anchorOrigin,
      action: key => (
        <Button onClick={() => closeSnackbar(key)} color='inherit'>
          {actionButtonText}
        </Button>
      ),
      ...props,
    });
  };

  const showDefault = useCallback(
    values =>
      showSnackbar({
        variant: 'default',
        SnackbarProps: { ...getTestId('default') },
        ...values,
      }),
    [showSnackbar]
  );

  const showInfo = useCallback(
    values =>
      showSnackbar({
        variant: 'info',
        SnackbarProps: { ...getTestId('info') },
        ...values,
      }),
    [showSnackbar]
  );

  const showSuccess = useCallback(
    values =>
      showSnackbar({
        variant: 'success',
        SnackbarProps: { ...getTestId('success') },
        ...values,
      }),
    [showSnackbar]
  );

  const showError = useCallback(
    values =>
      showSnackbar({
        variant: 'error',
        SnackbarProps: { ...getTestId('error') },
        ...values,
      }),
    [showSnackbar]
  );

  const showWarning = useCallback(
    values =>
      showSnackbar({
        variant: 'warning',
        SnackbarProps: { ...getTestId('warning') },
        ...values,
      }),
    [showSnackbar]
  );

  return { showWarning, showError, showSuccess, showInfo, showDefault };
};

export default useCustomSnackbar;
