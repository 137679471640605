import {
  addBusinessDays,
  formattedDayOfTheWeekDayMonthShipmentDate,
} from '../utils/date';

export const REQUIRED_TYPE = {
  MANDATORY: 'M',
  OPTIONAL: 'Y',
  NEEDLESS: 'N',
  DESCRIPTION: 'D',
};

export const NETWORK_KEYS = {
  NETWORK_KEY_10_30: '2^08', // 10:30
  NETWORK_KEY_12: '2^13', // 12:00
  NETWORK_KEY_NEXT_DAY: '2^12', // Next Day
  NETWORK_KEY_SHIP_TO_SHOP: '2^91', // Ship to Shop
  NETWORK_KEY_TWO_DAY: '2^11', // Two Day
};

export const PARCEL_ARRIVAL_DESCRIPTION =
  'We’ll collect your parcel for delivery';
export const DELIVERY_SPEEDY_CONTENT =
  'DPD Pickups usually deliver in 1 working day. Occasionally, due to collection schedules at your selected location, it might take 2-3 working days depending on the day and time you drop-off your parcel.';
export const PARCEL_TWO_DAYS_DESCRIPTION = (collectDate, deliverDate) =>
  `We'll collect on ${collectDate} and deliver ${deliverDate}. It might take 2-3 working days depending on the day on the day and time you drop-off your parcel.`;

export const PARCEL_ARRIVAL_TIMES = {
  BEFORE_10_30: 'Before 10:30',
  BEFORE_12: 'Before 12:00',
  NEXT_DAY: 'Parcel Next day',
  SHIP_TO_SHOP: 'Speedy Delivery with a Heads Up:',
  TWO_DAY: 'Two day Parcel',
};

export const NETWORK_DETAILS = {
  [NETWORK_KEYS.NETWORK_KEY_10_30]: {
    title: PARCEL_ARRIVAL_TIMES.BEFORE_10_30,
    desc: shippingDate =>
      `${PARCEL_ARRIVAL_DESCRIPTION} ${formattedDayOfTheWeekDayMonthShipmentDate(
        shippingDate
      )} before 10:30`,
  },
  [NETWORK_KEYS.NETWORK_KEY_12]: {
    title: PARCEL_ARRIVAL_TIMES.BEFORE_12,
    desc: shippingDate =>
      `${PARCEL_ARRIVAL_DESCRIPTION} ${formattedDayOfTheWeekDayMonthShipmentDate(
        shippingDate
      )} before 12:00`,
  },
  [NETWORK_KEYS.NETWORK_KEY_NEXT_DAY]: {
    title: PARCEL_ARRIVAL_TIMES.NEXT_DAY,
    desc: shippingDate =>
      `${PARCEL_ARRIVAL_DESCRIPTION} ${formattedDayOfTheWeekDayMonthShipmentDate(
        shippingDate
      )}`,
  },
  [NETWORK_KEYS.NETWORK_KEY_SHIP_TO_SHOP]: {
    title: PARCEL_ARRIVAL_TIMES.SHIP_TO_SHOP,
    desc: () => DELIVERY_SPEEDY_CONTENT,
  },
  [NETWORK_KEYS.NETWORK_KEY_TWO_DAY]: {
    title: PARCEL_ARRIVAL_TIMES.TWO_DAY,
    desc: shipmentDate =>
      PARCEL_TWO_DAYS_DESCRIPTION(
        formattedDayOfTheWeekDayMonthShipmentDate(shipmentDate),
        formattedDayOfTheWeekDayMonthShipmentDate(
          addBusinessDays(shipmentDate, 2)
        )
      ),
  },
};
