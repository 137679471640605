import { cloneDeep, get, set } from 'lodash';
import {
  ADDRESS_BOOK_FIELDS,
  SHIPMENT_FIELDS,
} from '../../../../constants/forms';
import { ShipmentHelper as PageShipmentHelpers } from '../../helpers';
import { ShipmentHelpers as BaseShipmentHelpers } from '../../../../helpers';
import * as STRINGS from '../../../../constants/strings';
import { REFERENCE_NAME } from '../../constants';

const setDefaultDeliveryContactFields = (clonedValues, values) => {
  set(
    clonedValues,
    SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY,
    get(values, SHIPMENT_FIELDS.DELIVERY_CONTACT_NAME.KEY, '')
  );
  set(
    clonedValues,
    SHIPMENT_FIELDS.DELIVERY_CONTACT_EMAIL.KEY,
    get(values, SHIPMENT_FIELDS.DELIVERY_CONTACT_EMAIL.KEY, '')
  );
  set(
    clonedValues,
    SHIPMENT_FIELDS.DELIVERY_CONTACT_MOBILE.KEY,
    get(values, SHIPMENT_FIELDS.DELIVERY_CONTACT_MOBILE.KEY, '')
  );
};

const setInvoiceExporterDetails = (clonedValues, values, addressBook) => {
  set(
    clonedValues,
    SHIPMENT_FIELDS.INVOICE_EXPORTER_IS_BUSINESS.KEY,
    PageShipmentHelpers.getDefaultIsBusiness(values, {
      businessPath: SHIPMENT_FIELDS.INVOICE_EXPORTER_IS_BUSINESS.KEY,
      organisationPath: SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY,
    })
  );

  set(
    clonedValues,
    SHIPMENT_FIELDS.INVOICE_EXPORTER_EORI_NUMBER.KEY,
    get(addressBook, ADDRESS_BOOK_FIELDS.EORI_NUMBER.KEY) || ''
  );
  set(
    clonedValues,
    SHIPMENT_FIELDS.INVOICE_EXPORTER_VAT_NUMBER.KEY,
    get(addressBook, ADDRESS_BOOK_FIELDS.VAT_NUMBER.KEY) || ''
  );

  if (BaseShipmentHelpers.isUkimsAvailable(clonedValues)) {
    set(
      clonedValues,
      SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.KEY,
      get(addressBook, ADDRESS_BOOK_FIELDS.UKIMS_NUMBER.KEY) || ''
    );
  }
};

const setInvoiceImporterDetails = (clonedValues, values, addressBook) => {
  const defaultIsBusiness = PageShipmentHelpers.getDefaultIsBusiness(values, {
    businessPath: SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY,
    organisationPath: SHIPMENT_FIELDS.DESTINATION_ORGANISATION.KEY,
  });

  set(
    clonedValues,
    SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY,
    defaultIsBusiness
  );

  if (defaultIsBusiness) {
    set(
      clonedValues,
      SHIPMENT_FIELDS.INVOICE_IMPORTER_EORI_NUMBER.KEY,
      get(addressBook, ADDRESS_BOOK_FIELDS.EORI_NUMBER.KEY) || ''
    );
    set(
      clonedValues,
      SHIPMENT_FIELDS.INVOICE_IMPORTER_VAT_NUMBER.KEY,
      get(addressBook, ADDRESS_BOOK_FIELDS.VAT_NUMBER.KEY) || ''
    );

    if (BaseShipmentHelpers.isUkimsAvailable(values)) {
      set(
        clonedValues,
        SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.KEY,
        get(addressBook, ADDRESS_BOOK_FIELDS.UKIMS_NUMBER.KEY) || ''
      );
    }
  } else {
    set(
      clonedValues,
      SHIPMENT_FIELDS.INVOICE_IMPORTER_PID_NUMBER.KEY,
      get(addressBook, ADDRESS_BOOK_FIELDS.PID_NUMBER.KEY) || ''
    );
  }
};

const setInvoiceFields = (
  clonedValues,
  values,
  deliveryAddressBook,
  collectionAddressBook
) => {
  set(clonedValues, SHIPMENT_FIELDS.CURRENCY.KEY, STRINGS.GBP);

  setInvoiceExporterDetails(clonedValues, values, collectionAddressBook);
  setInvoiceImporterDetails(clonedValues, values, deliveryAddressBook);
};

export const getInitialValues = (values, references) => {
  const { deliveryAddressBook, collectionAddressBook } = references || {};
  const clonedValues = cloneDeep(values);

  setDefaultDeliveryContactFields(clonedValues, values);
  if (BaseShipmentHelpers.isInvoiceAvailable(values)) {
    setInvoiceFields(
      clonedValues,
      values,
      deliveryAddressBook,
      collectionAddressBook
    );
  }

  return clonedValues;
};

export const getInvoiceAvailableFields = values => {
  const isInvoiceAvailable = BaseShipmentHelpers.isInvoiceAvailable(values);
  const isBusiness = get(
    values,
    SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY,
    false
  );
  const isUkimsAvailable = BaseShipmentHelpers.isUkimsAvailable(values);

  //TODO: add ioss field
  return {
    isInvoiceAvailable,
    [SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY]: isInvoiceAvailable,
    [SHIPMENT_FIELDS.INVOICE_IMPORTER_AT_RISK.KEY]:
      BaseShipmentHelpers.isAtRiskAvailable(values),
    [SHIPMENT_FIELDS.INVOICE_IMPORTER_EORI_NUMBER.KEY]: isBusiness,
    [SHIPMENT_FIELDS.INVOICE_IMPORTER_VAT_NUMBER.KEY]: isBusiness,
    [SHIPMENT_FIELDS.INVOICE_IMPORTER_PID_NUMBER.KEY]:
      isInvoiceAvailable && !isBusiness,
    [SHIPMENT_FIELDS.INVOICE_IMPORTER_UKIMS_NUMBER.KEY]: isUkimsAvailable,
    [SHIPMENT_FIELDS.INVOICE_EXPORTER_EORI_NUMBER.KEY]: isInvoiceAvailable,
    [SHIPMENT_FIELDS.INVOICE_EXPORTER_VAT_NUMBER.KEY]: isInvoiceAvailable,
    [SHIPMENT_FIELDS.INVOICE_EXPORTER_FDA_NUMBER.KEY]:
      BaseShipmentHelpers.isFdaNumberAvailable(values),
    [SHIPMENT_FIELDS.INVOICE_EXPORTER_UKIMS_NUMBER.KEY]: isUkimsAvailable,
  };
};

export const getAllowedFields = (values, references, currentSession) => ({
  ...getInvoiceAvailableFields(values),
  [SHIPMENT_FIELDS.COLLECTION_ORGANISATION.KEY]: get(
    values,
    SHIPMENT_FIELDS.INVOICE_EXPORTER_IS_BUSINESS.KEY,
    false
  ),
  [SHIPMENT_FIELDS.DESTINATION_ORGANISATION.KEY]: get(
    values,
    SHIPMENT_FIELDS.INVOICE_IMPORTER_IS_BUSINESS.KEY,
    false
  ),
  saveForNextTime:
    !get(values, SHIPMENT_FIELDS.COLLECTION_ADDRESS_BOOK_ID.KEY) &&
    currentSession?.uid,
  saveForNextTimeReceiver:
    !get(values, SHIPMENT_FIELDS.DELIVERY_ADDRESS_BOOK_ID.KEY) &&
    currentSession?.uid,
  collectionPickupPoint: references[REFERENCE_NAME.COLLECTION_PICKUP],
  destinationPickupPoint: references[REFERENCE_NAME.DELIVERY_PICKUP],
});

export const getActivePickupPoints = references => ({
  collectionPickupPoint: references[REFERENCE_NAME.COLLECTION_PICKUP],
  destinationPickupPoint: references[REFERENCE_NAME.DELIVERY_PICKUP],
});
