export const GB_PHONE_NUMBER_REGEXP = /^(\+44|0044)?0?7[0-9]{9}$/;

export const COMMON_PHONE_NUMBER_REGEXP = /^\+?\d{1,15}$/;

export const UK_POST_CODE_FULL =
  /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/;

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const COUNTRY_CODE = /^[A-Z][A-Z]$/;

// British Forces Post Office "BFPO" postcodes patterns
export const BFPO_POSTCODES = [
  /^BFPO\s?\d{1,4}$/i, // Matches "BFPO" followed by up to 4 digits (e.g., "BFPO 1234")
  /^BF\d{1,2}\s?\d{1}[A-Z]{2}$/i, // Matches BF1, BF2, etc. with standard UK postcode format (e.g., "BF1 2AA")
];

export const GB_POSTCODE_PATTERN =
  /^(GIR[ ]?0AA)|([a-zA-Z]{1,2}(([0-9]{1,2})|([0-9][a-zA-Z]))[ ]?[0-9][a-zA-Z]{2})$/;

export const PARCEL_NUMBER_REGEXP = /^\d{14}$/;

export const BUSINESS_EORI_REGEX = /^(GBUNREG)$|^((GB)\d{12})$/i;
